import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import auth from "../utils/auth";
import { useAuth } from "../App";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { message, Space } from "antd";
import ZengradeLogo from "../assets/zengrade-logo-nobackground.svg";

const LoginPage = () => {
  let navigate = useNavigate();
  let { user } = useAuth();
  let location = useLocation();
  const firebaseuiElement = useRef(null);

  const signOut = () => {
    auth
      .signOut()
      .then(function () {
        navigate("/login", { replace: true });
      })
      .catch(function () {
        navigate("/login", { replace: true });
      });
  };

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      // {
      //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      //   requireDisplayName: false,
      // },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        // buttonColor: "#4285F4",
      },
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        navigate("/view", { replace: true });
      },
      signInFailure: (err) => {
        console.error(`[Anmeldefehler] FEHLER: ${err.message}`);
      },
    },
  };

  // useEffect(() => {
  //   const updateButtonLabels = () => {
  //     const buttons = document.querySelectorAll(
  //       '.firebaseui-idp-button[data-provider-id="password"]'
  //     );
  //     buttons.forEach((button, index) => {
  //       const textElement = button.querySelector(".firebaseui-idp-text");
  //       if (textElement) {
  //         if (index === 0) {
  //           textElement.textContent = "Mit E-Mail und Passwort anmelden";
  //         } else if (index === 1) {
  //           textElement.textContent = "Mit E-Mail-Link anmelden";
  //         }
  //       }
  //     });
  //   };

  //   // Warten Sie einen Moment, bis FirebaseUI die Buttons gerendert hat
  //   setTimeout(updateButtonLabels, 100);
  // }, []);

  useEffect(() => {
    const updateUIText = () => {
      const emailSignInLabel = document.querySelector(".firebaseui-title");
      if (emailSignInLabel) {
        if (emailSignInLabel.textContent === "Sign in with email") {
          emailSignInLabel.textContent = "Zugangslink anfordern";
        } else if (emailSignInLabel.textContent === "Sign-in email sent") {
          emailSignInLabel.textContent = "Zugangslink versendet";
        }
      }

      const signInEmailSent = document.querySelector(".firebaseui-text");
      if (
        signInEmailSent &&
        signInEmailSent.textContent.includes(
          "A sign-in email with additional instructions was sent to"
        )
      ) {
        const emailElement = signInEmailSent.querySelector("strong");
        if (emailElement) {
          const email = emailElement.textContent;
          signInEmailSent.innerHTML = `Eine E-Mail mit Zugangslink wurde gesendet an <strong>${email}</strong>. Bitte überprüfen Sie Ihr E-Mail-Postfach.`;
        }
      }

      const emailLabel = document.querySelector(
        'label[for="ui-sign-in-email-input"]'
      );
      if (emailLabel && emailLabel.textContent === "Email") {
        emailLabel.textContent =
          "dienstliche E-Mail-Adresse aus Sicherheitsgründen";
      }

      const emailError = document.querySelector(".firebaseui-id-email-error");
      if (
        emailError &&
        emailError.textContent === "Enter your email address to continue"
      ) {
        emailError.textContent =
          "Geben Sie Ihre E-Mail-Adresse ein, um fortzufahren";
      }

      const submitButton = document.querySelector(".firebaseui-id-submit");
      if (submitButton && submitButton.textContent === "Next") {
        submitButton.textContent = "Weiter";
      }

      const backButton = document.querySelector(
        ".firebaseui-id-secondary-link"
      );
      if (backButton && backButton.textContent === "Back") {
        backButton.textContent = "Zurück";
      }

      const troubleLink = document.querySelector(
        ".firebaseui-id-trouble-getting-email-link"
      );
      if (troubleLink && troubleLink.textContent === "Trouble getting email?") {
        troubleLink.textContent = "Probleme bei Empfang der E-Mail?";
      }

      const troubleGettingEmailPage = document.querySelector(
        ".firebaseui-id-page-email-not-received"
      );
      if (troubleGettingEmailPage) {
        const title =
          troubleGettingEmailPage.querySelector(".firebaseui-title");
        if (title && title.textContent === "Trouble getting email?") {
          title.textContent = "Probleme beim Empfang der E-Mail?";
        }

        const paragraphs =
          troubleGettingEmailPage.querySelectorAll(".firebaseui-text");
        paragraphs.forEach((p) => {
          if (p.textContent === "Try these common fixes:") {
            p.textContent = "Versuchen Sie diese häufigen Lösungen:";
          } else if (p.textContent.includes("If the steps above didn't work")) {
            p.textContent =
              "Wenn die obigen Schritte nicht funktioniert haben, können Sie die E-Mail erneut senden. Beachten Sie, dass dies den Link in der älteren E-Mail deaktiviert.";
          }
        });

        const listItems = troubleGettingEmailPage.querySelectorAll("ul li");
        const germanListItems = [
          "Überprüfen Sie, ob die E-Mail als Spam markiert oder gefiltert wurde.",
          "Überprüfen Sie Ihre Internetverbindung.",
          "Überprüfen Sie, ob Sie Ihre E-Mail-Adresse richtig geschrieben haben.",
          "Überprüfen Sie, ob Ihr Posteingang voll ist oder ob es andere Einstellungsprobleme gibt.",
        ];
        listItems.forEach((li, index) => {
          if (index < germanListItems.length) {
            li.textContent = germanListItems[index];
          }
        });
        const resendLink = troubleGettingEmailPage.querySelector(
          ".firebaseui-id-resend-email-link"
        );
        if (resendLink && resendLink.textContent === "Resend") {
          resendLink.textContent = "Erneut senden";
        }
      }
    };

    // Warten Sie einen Moment, bis FirebaseUI die Elemente gerendert hat
    setTimeout(updateUIText, 100);

    const observeDOM = () => {
      const targetNode = document.body;
      const config = { childList: true, subtree: true };

      const callback = function (mutationsList, observer) {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            updateUIText();
          }
        }
      };

      const observer = new MutationObserver(callback);
      observer.observe(targetNode, config);

      return observer;
    };

    const observer = observeDOM();
    updateUIText();

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     const redirectTo = location.state?.from?.pathname || "/view";
  //     navigate(redirectTo, { replace: true });
  //     return;
  //   }

  //   // https://github.com/firebase/firebaseui-web-react/issues/21#:~:text=working%20on%20my-,project,-.%20This%20was%20inspired
  //   const loadFirebaseUI = async () => {
  //     await import(
  //       `https://www.gstatic.com/firebasejs/ui/6.0.0/firebase-ui-auth__de.js`
  //     );
  //     window.firebase = firebase;
  //     const firebaseuiUMD = window.firebaseui;
  //     let ui =
  //       firebaseuiUMD.auth.AuthUI.getInstance() ||
  //       new firebaseuiUMD.auth.AuthUI(auth);
  //     ui.start(firebaseuiElement.current, uiConfig);
  //   };

  //   loadFirebaseUI();
  // }, [user, navigate, location]);

  useEffect(() => {
    if (user) {
      const redirectTo = location.state?.from?.pathname || "/view";
      navigate(redirectTo, { replace: true });
      return;
    }

    const loadFirebaseUI = () => {
      window.firebase = firebase;
      let ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(auth);
      ui.start(firebaseuiElement.current, uiConfig);
    };

    loadFirebaseUI();
  }, [user, navigate, location]);

  return (
    <Space
      direction="vertical"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%", // Ensure full widths
      }}
    >
      <img
        src={ZengradeLogo}
        alt="Logo"
        style={{ width: "200px", height: "200px" }}
      />
      {!user && (
        <div style={{ width: "400px" }}>
          {/* <Text>Anmelden</Text> */}
          <div ref={firebaseuiElement}></div>
        </div>
      )}
      {user && (
        <React.Fragment>
          <p style={{ textAlign: "center" }}>
            Willkommen, {user.displayName} <br />
            <small>{user.email}</small> <br />
            <button onClick={signOut}>Abmelden</button>
          </p>
        </React.Fragment>
      )}
    </Space>
  );
};

export default LoginPage;
