import React from "react";
import { Row, Col, Card, Typography, Button, Space } from "antd";

const { Title, Text } = Typography;

const SubscriptionContent = ({ onSubscribe }) => {
  return (
    <>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card
            title={<Title level={3}>zengrade Silber</Title>}
            style={{ textAlign: "center" }}
          >
            <Text>
              100 zenpoints monatlich inkl. & 0.30 € pro zusätzlichem zenpoint
            </Text>
            <Title style={{ marginBottom: 16 }}>
              25 €{" "}
              <Text style={{ fontSize: "16px" }}>pro Monat zzgl. MwSt.</Text>
            </Title>
            <Text>
              <ul style={{ textAlign: "left", paddingLeft: "20px" }}>
                <li>1 zenpoint = 1 DIN A4 Seite</li>
                <li>
                  100 zenpoints monatlich inkl. (bis Monatsende einlösbar)
                </li>
                <li>Option zusätzliche zenpoints zu kaufen</li>
                <li>
                  0.30 € pro zusätzlichem zenpoint (zeitl. unbegrenzt einlösbar)
                </li>
                <li>Abo-Beginn ab Tag des Abschlusses</li>
                <li>monatlich kündbar</li>
              </ul>
            </Text>
            <br />
            <br />
            <Button
              type="primary"
              size="large"
              block
              onClick={() => onSubscribe("Silber")}
            >
              Abonnieren
            </Button>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card
            title={<Title level={3}>zengrade Gold</Title>}
            style={{ textAlign: "center" }}
          >
            <Text>
              220 zenpoints monatlich inkl. & 0.25 € pro zusätzlichem zenpoint
            </Text>
            <Title style={{ marginBottom: 16 }}>
              50 €{" "}
              <Text style={{ fontSize: "16px" }}>pro Monat zzgl. MwSt.</Text>
            </Title>
            <Text>
              <ul style={{ textAlign: "left", paddingLeft: "20px" }}>
                <li>1 zenpoint = 1 DIN A4 Seite</li>
                <li>
                  220 zenpoints monatlich inkl. (bis Monatsende einlösbar)
                </li>
                <li>Option zusätzliche zenpoints zu kaufen</li>
                <li>
                  0.25 € pro zusätzlichem zenpoint (zeitl. unbegrenzt einlösbar)
                </li>
                <li>Abo-Beginn ab Tag des Abschlusses</li>
                <li>monatlich kündbar</li>
              </ul>
            </Text>
            <br />
            <br />
            <Button
              type="primary"
              size="large"
              block
              onClick={() => onSubscribe("Gold")}
            >
              Abonnieren
            </Button>
          </Card>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 16 }}>
        <Space size="middle">
          <a
            href="https://zengrade.notion.site/Nutzungsbedingungen-1d0dd627d65e423e90b14fb27a3ea75a"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nutzungsbedingungen
          </a>
          <a
            href="https://zengrade.notion.site/Datenschutzerkl-rung-3ab3c80c135c458494e8b6f7f1d6b727"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutzerklärung
          </a>
          <a
            href="https://zengrade.notion.site/Auftragsverarbeitungsvertrag-a5c85050bd104bed8a0469db826b20b5?pvs=74"
            target="_blank"
            rel="noopener noreferrer"
          >
            Auftragsverarbeitungsvertrag
          </a>
        </Space>
      </Row>
    </>
  );
};

export default SubscriptionContent;
