import { useState } from "react";
import { message } from "antd";
import { collection, addDoc, onSnapshot } from "firebase/firestore";
import db from "../utils/db";

export const useSubscription = (user) => {
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] =
    useState(false);

  const showSubscriptionModal = () => {
    setIsSubscriptionModalVisible(true);
  };

  const handleSubscriptionModalCancel = () => {
    setIsSubscriptionModalVisible(false);
  };

  const purchaseSubscription = async (plan) => {
    message.loading("Sie werden weitergeleitet...");

    setLoadingStripe(true);
    try {
      const priceId =
        plan === "Silber"
          ? "price_1PlTbiIBcEHZy5P9GqesgWVy"
          : "price_1PlTbdIBcEHZy5P9rUiZxVA0";

      const docRef = await addDoc(
        collection(db, "customers", user.uid, "checkout_sessions"),
        {
          mode: "subscription",
          line_items: [
            {
              price: priceId,
              quantity: 1,
            },
          ],
          success_url: window.location.origin + "?payment_success=true",
          cancel_url: window.location.origin,
          client_reference_id: user.uid,
          automatic_tax: { enabled: true },
          allow_promotion_codes: true,
          consent_collection: {
            terms_of_service: "required",
          },
          locale: "de",
          invoice_creation: {
            enabled: true,
          },
        }
      );

      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          console.error(`An error occurred: ${error.message}`);
          setLoadingStripe(false);
        }
        if (url) {
          window.location.assign(url);
        }
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
      message.error(
        "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
      );
    } finally {
      setLoadingStripe(false);
    }
  };

  const handleSubscribe = (plan) => {
    setIsSubscriptionModalVisible(false);
    purchaseSubscription(plan);
  };

  return {
    loadingStripe,
    isSubscriptionModalVisible,
    showSubscriptionModal,
    handleSubscriptionModalCancel,
    handleSubscribe,
  };
};
