import React, { useState, useEffect } from "react";

import { useAuth } from "../App";

import { v4 as uuidv4 } from "uuid";

import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import db from "../utils/db";

import "./CreateExamPage.css";

export const defaultGrades = [
  { grade: "15", minPercentage: 95 },
  { grade: "14", minPercentage: 90 },
  { grade: "13", minPercentage: 85 },
  { grade: "12", minPercentage: 80 },
  { grade: "11", minPercentage: 75 },
  { grade: "10", minPercentage: 70 },
  { grade: "9", minPercentage: 65 },
  { grade: "8", minPercentage: 60 },
  { grade: "7", minPercentage: 55 },
  { grade: "6", minPercentage: 50 },
  { grade: "5", minPercentage: 45 },
  { grade: "4", minPercentage: 40 },
  { grade: "3", minPercentage: 33 },
  { grade: "2", minPercentage: 27 },
  { grade: "1", minPercentage: 20 },
  { grade: "0", minPercentage: 0 },
];

export const alternativeGrades = [
  { grade: "1+", minPercentage: 95 },
  { grade: "1", minPercentage: 90 },
  { grade: "1-", minPercentage: 85 },
  { grade: "2+", minPercentage: 80 },
  { grade: "2", minPercentage: 75 },
  { grade: "2-", minPercentage: 70 },
  { grade: "3+", minPercentage: 65 },
  { grade: "3", minPercentage: 60 },
  { grade: "3-", minPercentage: 55 },
  { grade: "4+", minPercentage: 50 },
  { grade: "4", minPercentage: 45 },
  { grade: "4-", minPercentage: 40 },
  { grade: "5+", minPercentage: 35 },
  { grade: "5", minPercentage: 30 },
  { grade: "5-", minPercentage: 25 },
  { grade: "6", minPercentage: 0 },
];

const CreateExamPage = () => {
  let { user } = useAuth();

  const [customUuid, setCustomUuid] = useState("");

  // Add userData hook
  const [userData] = useDocumentData(doc(db, `users`, user.uid));

  useEffect(() => {
    // Generate a UUID and modify it to match the specified length
    let generatedUuid = uuidv4().replace(/-/g, ""); // Remove dashes
    let mixedCaseUUID = "";

    // Randomly convert each character to upper or lower case
    for (let char of generatedUuid) {
      mixedCaseUUID +=
        Math.random() > 0.5 ? char.toUpperCase() : char.toLowerCase();
    }

    // Ensure the UUID is exactly 20 characters long
    if (mixedCaseUUID.length > 20) {
      mixedCaseUUID = mixedCaseUUID.substring(0, 20); // Trim if longer
    } else {
      while (mixedCaseUUID.length < 20) {
        mixedCaseUUID += "0"; // Pad with zeros if shorter
      }
    }
    setCustomUuid(mixedCaseUUID);
  }, []);

  return (
    <React.Fragment>
      <iframe
        // id="JotFormIFrame-242522469445359" // old id
        id="JotFormIFrame-242972842370360"
        title="Erwartungshorizont anlegen"
        onload="window.parent.scrollTo(0,0)"
        src={`https://form.jotform.com/242972842370360?userid=${
          user.uid
        }&examid=${customUuid}${
          userData?.isRestricted ? "&isrestricted=1" : ""
        }`}
        frameborder="0"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          border: "none",
          overflow: "hidden",
        }}
      ></iframe>
    </React.Fragment>
  );
};

export default CreateExamPage;
