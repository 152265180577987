import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { useParams, useNavigate } from "react-router-dom";

import { useAuth } from "../App";

import storage from "../utils/storage";
import db from "../utils/db"; // Assuming you have a module to export your Firestore db instance
import { doc, setDoc, collection, query, where } from "firebase/firestore"; // Import Firestore document reference and setDoc
import { ref, uploadBytesResumable } from "firebase/storage";

import {
  useDocumentData,
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";

import { useSubscription } from "../hooks/useSubscription";
import SubscriptionContent from "../components/SubscriptionContent";

import {
  Modal,
  message,
  Alert,
  Button,
  Space,
  Checkbox,
  Upload,
  Progress,
  Popconfirm,
  Input,
  Tooltip,
  Radio,
  notification,
  Card,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import StepsComponent from "../components/StepsComponent";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFUploadPage = () => {
  let navigate = useNavigate();

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);

  let { user } = useAuth();

  const { examIdProp } = useParams();

  const [userData, userLoading, userDataError] = useDocumentData(
    doc(db, `users`, user.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const [
    subscriptionCollection,
    // eslint-disable-next-line no-unused-vars
    subscriptionCollectionLoading,
    // eslint-disable-next-line no-unused-vars
    subscriptionCollectionError,
  ] = useCollection(
    query(collection(db, `customers/${user.uid}/subscriptions`)),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const hasActiveSubscription =
    subscriptionCollection &&
    subscriptionCollection.docs.length > 0 &&
    subscriptionCollection.docs.some((doc) => doc.data().status === "active");

  const uploadToStorage = async (options) => {
    const { file, onSuccess, onError, onProgress } = options;

    setIsUploading(true); // Set loading state to true

    // After successful upload, set isUploaded to true for the exam
    const examDocRef = doc(db, `users/${user.uid}/exams`, examIdProp);
    await setDoc(
      examDocRef,
      {
        selectedPages: selectedPages,
      },
      { merge: true }
    );

    try {
      // Create a reference to the location where you want to store the file
      const storageRef = ref(
        storage,
        `users/${user.uid}/exams/${examIdProp}/submissions/upload.pdf`
      );

      // Start the upload task
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress); // Update the upload progress state
          onProgress({ percent: progress });
        },
        (error) => {
          setIsUploading(false);
          setUploadProgress(null); // Reset the upload progress state on error
          onError(error);
        },
        async () => {
          // After successful upload, set isUploaded to true for the exam
          const examDocRef = doc(db, `users/${user.uid}/exams`, examIdProp);
          await setDoc(
            examDocRef,
            {
              isUploaded: true,
              selectedPages: selectedPages,
              processing: true,
            },
            { merge: true }
          );

          // if (user.uid === "dWFOfzm6d0ewgNEuT8bTk9uVPQe2") {
          // const userDocRef = doc(db, `users`, user.uid);
          // // Subtrahiere die Anzahl der Seiten von den Credits des Benutzers
          // const newCredits = (userData?.credits || 0) - numPages;
          // await setDoc(userDocRef, { credits: newCredits }, { merge: true });

          // message.success(
          //   `Es wurden ${numPages} zenpoints abgezogen. Verbleibende zenpoints: ${newCredits}`
          // );

          // Deduct zenpoints based on the selected source
          // if (selectedCreditSource === "personal") {
          const userDocRef = doc(db, `users`, user.uid);
          const subscriptionCredits = userData?.subscriptionCredits || 0;
          const permanentCredits = userData?.permanentCredits || 0;

          let newSubscriptionCredits = subscriptionCredits;
          let newPermanentCredits = permanentCredits;
          let remainingPages = numPages;

          if (subscriptionCredits >= numPages) {
            newSubscriptionCredits -= numPages;
          } else {
            newSubscriptionCredits = 0;
            remainingPages -= subscriptionCredits;
            newPermanentCredits = Math.max(
              0,
              permanentCredits - remainingPages
            );
          }

          await setDoc(
            userDocRef,
            {
              subscriptionCredits: newSubscriptionCredits,
              permanentCredits: newPermanentCredits,
            },
            { merge: true }
          );

          notification.success({
            message: "PDF Upload erfolgreich",
            description: `Sie haben ${numPages} zenpoints verwendet. Ihr verbleibendes Guthaben beträgt ${
              newPermanentCredits + newSubscriptionCredits
            } zenpoints.`,
            duration: 15,
            placement: "bottom",
          });

          setIsUploading(false);
          setUploadProgress(null); // Reset the upload progress state on completion
          onSuccess(null, file);
        }
      );
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsUploading(false); // Set loading state to false in case of an error
      onError(error);
    }
  };

  const [numPages, setNumPages] = useState(null);
  const [selectedPages, setSelectedPages] = useState([1]);
  const [file, setFile] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState({
    scanOptions: {
      scanQuality: true,
      dpi300: true,
      formatA4: true,
      colorScan: true,
      order: true,
      rotation: true,
    },
    documentFeatures: {
      blue: true,
      handwritten: true,
      singleTask: true,
      lineatur: true,
    },
  });

  const onCheckboxChange = (group, e) => {
    if (e.target.name === "confirmTexts") {
      setConfirmTexts(e.target.checked);
    }
  };

  const allChecked =
    Object.values(checkboxValues.scanOptions).every(
      (value) => value === true
    ) &&
    Object.values(checkboxValues.documentFeatures).every(
      (value) => value === true
    );

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // function togglePageSelection(pageNum) {
  //   setSelectedPages((prevSelectedPages) => {
  //     if (prevSelectedPages.includes(pageNum)) {
  //       return prevSelectedPages.filter((page) => page !== pageNum);
  //     } else {
  //       return [...prevSelectedPages, pageNum];
  //     }
  //   });
  // }

  function togglePageSelection(pageNum) {
    if (pageNum === 1) return; // Prevent deselecting the first page

    setSelectedPages((prevSelectedPages) => {
      if (prevSelectedPages.includes(pageNum)) {
        return prevSelectedPages.filter((page) => page !== pageNum);
      } else {
        return [...prevSelectedPages, pageNum].sort((a, b) => a - b);
      }
    });
  }

  function getStudentTextInfo(pageNum) {
    if (selectedPages.length === 1) return null; // No info if only the first page is selected

    for (let i = 0; i < selectedPages.length; i++) {
      if (pageNum < selectedPages[i]) {
        const studentTextNumber = i;
        const pageInStudentText = pageNum - selectedPages[i - 1] + 1;
        return { studentTextNumber, pageInStudentText };
      }
    }

    const studentTextNumber = selectedPages.length;
    const pageInStudentText =
      pageNum - selectedPages[selectedPages.length - 1] + 1;
    return { studentTextNumber, pageInStudentText };
  }

  const getStudentTextRanges = () => {
    if (numPages === 1) {
      return [`Aufsatz 1: Seite 1`];
    }

    if (selectedPages.length === 1) {
      return [`Aufsatz 1: Seite 1 - ${numPages}`];
    }

    const ranges = [];
    for (let i = 0; i < selectedPages.length; i++) {
      const start = selectedPages[i];
      const end = selectedPages[i + 1] ? selectedPages[i + 1] - 1 : numPages;
      if (start === end) {
        ranges.push(`Aufsatz ${i + 1}: Seite ${start}`);
      } else {
        ranges.push(`Aufsatz ${i + 1}: Seite ${start} - ${end}`);
      }
    }
    return ranges;
  };

  const [confirmTexts, setConfirmTexts] = useState(false);

  const hasAufsatzOverLimit = () => {
    const pageLimit = 22;

    if (numPages <= pageLimit) return false;

    if (selectedPages.length === 1) return true;

    for (let i = 0; i < selectedPages.length; i++) {
      const start = selectedPages[i];
      const end = selectedPages[i + 1] ? selectedPages[i + 1] - 1 : numPages;
      if (end - start + 1 > pageLimit) return true;
    }
    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
    // Setzen Sie den Upload-Fortschritt zurück, wenn das Modal geöffnet wird
    setUploadProgress(null);
  };

  const handlePdfUpload = async () => {
    if (!file) {
      message.error("Bitte wählen Sie zuerst ein PDF aus.");
      return;
    }

    const uploadOptions = {
      file: file,
      onSuccess: () => {
        navigate(`/status/${examIdProp}`);
      },
      onError: (error) =>
        message.error(`Fehler beim Hochladen: ${error.message}`),
      onProgress: (progress) =>
        console.log(`Upload progress: ${progress.percent}%`),
    };

    await uploadToStorage(uploadOptions);
  };

  const handleOk = () => {
    // setIsModalVisible(false);
    handlePdfUpload(); // Startet den Upload-Prozess, wenn der Benutzer auf "PDF Upload starten" klickt
    console.log("PDF Upload gestartet");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCopyUrl = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      message.success("Link wurde in die Zwischenablage kopiert", 5);
      handleCancel();
    } catch (err) {
      message.error("Fehler beim Kopieren des Links", 5);
    }
  };

  const totalCredits =
    (userData?.subscriptionCredits || 0) + (userData?.permanentCredits || 0);

  const hasEnoughCredits = totalCredits >= numPages;

  const {
    loadingStripe,
    isSubscriptionModalVisible,
    showSubscriptionModal,
    handleSubscriptionModalCancel,
    handleSubscribe,
  } = useSubscription(user);

  if (totalCredits === 0 && !hasActiveSubscription) {
    return (
      <div style={{ maxWidth: 800, margin: "0 auto", padding: "20px" }}>
        <p>
          Sie haben keine verfügbaren zenpoints, um ein PDF hochzuladen. Bitte
          wählen Sie ein Abonnement, um fortzufahren:
        </p>
        <SubscriptionContent onSubscribe={handleSubscribe} />
      </div>
    );
  }

  const customItemRender = (originNode, file, fileList) => {
    return (
      <center>
        <small>{file.name}</small>
      </center>
    );
  };

  const isDisabled = totalCredits === 0;

  const uploadButton = (
    <Button disabled={isDisabled} icon={<UploadOutlined />}>
      PDF auswählen
    </Button>
  );

  return (
    <>
      <StepsComponent step={1} />

      <div style={{ marginBottom: "100px" }}>
        <Modal
          title="PDF-Upload"
          open={isModalVisible}
          maskClosable={false}
          closable={false}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Popconfirm
              title={
                <>
                  <div style={{ width: "300px" }}>
                    Um das PDF zu einem späteren Zeitpunkt hochzuladen, sichern
                    Sie sich folgenden Link:
                  </div>
                  <br />
                  <Input
                    size="small"
                    width={"100%"}
                    value={`https://app.zengrade.com/upload/${examIdProp}`}
                    readOnly
                  />
                </>
              }
              onConfirm={() =>
                handleCopyUrl(`https://app.zengrade.com/upload/${examIdProp}`)
              }
              onCancel={handleCancel}
              okText="Kopieren"
              cancelText="Fortfahren mit Abbrechen"
            >
              <Button>Abbrechen</Button>
            </Popconfirm>,
            <Button
              key="submit"
              type="primary"
              disabled={!allChecked || isUploading || !confirmTexts}
              onClick={handleOk}
            >
              PDF Upload starten
            </Button>,
          ]}
          bodyStyle={{ maxHeight: "60vh", overflowY: "auto" }}
        >
          <small>
            Um die bestmöglichen Ergebnisse zu erzielen, sollten Bedingungen
            geschaffen werden, die es der Handschrifterkennung leicht macht,
            gute Ergebnisse zu liefern. Daher geben wir Empfehlungen dazu, wie
            die Handschrifterkennung aktiv unterstützt werden kann. Die
            Empfehlungen müssen nicht zwingend eingehalten werden, wir
            garantieren bestmögliche Ergebnisse aber nur dann. Insgesamt ist die
            Bereitstellung sauberer Daten essenziell, weil nur so die
            Genauigkeit unserer Handschrifterkennung maximiert werden kann:
          </small>
          <div style={{ marginTop: "20px" }}>
            <p>
              <b>Einteilung der Aufsätze:</b>
            </p>
            <ul>
              {getStudentTextRanges().map((range, index) => (
                <li key={index}>{range}</li>
              ))}
            </ul>
            {hasAufsatzOverLimit() && (
              <Alert
                message={`Es können maximal 22 Seiten pro Aufsatz verarbeitet werden. Bitte passen Sie die Einteilung an.`}
                type="error"
                showIcon
                style={{ marginBottom: "10px" }}
              />
            )}
            <Checkbox
              name="confirmTexts"
              onChange={(e) => setConfirmTexts(e.target.checked)}
              disabled={hasAufsatzOverLimit()}
            >
              Einteilung korrekt (nachträglich nicht veränderbar)
            </Checkbox>
          </div>
          <div>
            <p>
              <b>Merkmale eines optimalen Scans:</b>
            </p>
            <Checkbox name="scanQuality" checked={true} disabled={true}>
              Scan am Multifunktionsgerät, keine Fotos
            </Checkbox>
            <br />
            <Checkbox name="dpi300" checked={true} disabled={true}>
              Auflösung 300 dpi
            </Checkbox>
            <br />
            <Checkbox name="colorScan" checked={true} disabled={true}>
              Scan in Farbe
            </Checkbox>
            <br />
            <Checkbox name="formatA4" checked={true} disabled={true}>
              Hochformat A4
            </Checkbox>
            <br />
            <Checkbox name="order" checked={true} disabled={true}>
              richtige Reihenfolge der Seiten
            </Checkbox>
            <br />
            <Checkbox name="rotation" checked={true} disabled={true}>
              Seiten nicht gedreht
            </Checkbox>
          </div>
          <br />
          <small>
            Wichtig: Wählen Sie am Scanner nicht die automatische Erkennung von
            Format, Farbe, etc.
          </small>
          <br />
          <br />
          <p>
            <b>Merkmale eines optimalen Dokuments:</b>
          </p>
          <div>
            <Checkbox name="handwritten" checked={true} disabled={true}>
              handschriftlich auf Zettel (kein getippter Text/Stylus)
            </Checkbox>
            <br />
            <Checkbox name="blue" checked={true} disabled={true}>
              blaue Schrift
            </Checkbox>
            <br />
            <Checkbox name="lineatur" checked={true} disabled={true}>
              Bogen mit Lineatur 27, Schrift in jede 2. Zeile
            </Checkbox>
            <br />
            <Checkbox name="singleTask" checked={true} disabled={true}>
              nur eine Aufgabe
            </Checkbox>
            <br />
            <br />
            {isUploading && (
              <>
                <br />
                <br />
                <Progress
                  size={"small"}
                  type="circle"
                  percent={Math.round(uploadProgress)}
                  showInfo={true}
                />
              </>
            )}
          </div>
        </Modal>
        {/* Add margin to avoid overlap with the fixed area */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "20px 0",
          }}
        >
          <Card title="Erklärvideo zur Benutzung des PDF-Uploads">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/iNtKbPNY4g4?si=OacMb74bGrM7pDhA"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Card>
          {/* {!file && (
            <Alert
              message={`Bitte wählen Sie ein PDF aus, um fortzufahren.`}
              type="info"
              showIcon
              style={{ marginBottom: "20px" }}
            />
          )} */}
          {!file && (
            <>
              <Alert
                message={
                  <>
                    Bitte wählen Sie ein PDF aus, um fortzufahren. Die
                    Verarbeitung einer Seite kostet einen zenpoint.
                    {" Verfügbare zenpoints: "}
                    {(userData?.subscriptionCredits || 0) +
                      (userData?.permanentCredits || 0)}
                  </>
                }
                type="info"
                showIcon
                style={{ marginBottom: "20px" }}
              />
              <Alert
                message={
                  "Bitte überprüfen Sie die PDF-Datei mit den Scans vor dem Upload. Die Seiten der Aufsätze können nicht nachträglich in die richtige Reihenfolge gebracht werden."
                }
                type={"warning"}
                style={{ marginBottom: "20px" }}
                showIcon
              />
            </>
          )}

          {file && (
            <>
              <Alert
                message={(() => {
                  return `Die Verarbeitung kostet ${numPages} zenpoint(s). ${
                    hasEnoughCredits
                      ? `Sie haben eine ausreichende Anzahl an zenpoints.`
                      : `Sie haben keine ausreichende Anzahl an zenpoints. Bitte laden Sie die zenpoints auf.`
                  }`;
                })()}
                type={(() => {
                  return totalCredits >= numPages ? "info" : "error";
                })()}
                style={{ marginBottom: "20px" }}
                showIcon
              />
            </>
          )}

          <Upload
            accept="application/pdf"
            beforeUpload={(file) => {
              setSelectedPages([1]);
              setFile(file);
              return false;
            }}
            maxCount={1}
            itemRender={customItemRender}
          >
            {isDisabled ? (
              <Tooltip title="Sie haben keine verfügbaren zenpoints. Bitte laden Sie Ihr Konto auf.">
                {uploadButton}
              </Tooltip>
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        {file && (
          <>
            <div style={{ width: "50%", margin: "0 auto" }}>
              <Alert
                message={
                  <>
                    Bitte zeigen Sie zengrade, wo die einzelnen Aufsätze im PDF
                    anfangen, indem Sie jeweils{" "}
                    <b>die erste Seite eines Aufsatzes </b> anklicken. Beispiel:
                    Sie laden ein 15-seitiges PDF hoch und wählen Seite 1, 5 und
                    10 aus. Das PDF wird in 3 Aufsätze unterteilt:
                    <ul>
                      <li>Aufsatz 1: Seite 1 - 4</li>
                      <li>Aufsatz 2: Seite 5 - 9</li>
                      <li>Aufsatz 3: Seite 10 - 15</li>
                    </ul>
                  </>
                }
                type="warning"
                // showIcon
              />
            </div>

            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              error={
                "Fehler beim Laden des PDFs: Bitte verwenden Sie einen der folgenden unterstützten Browser: Chrome ≥92, Edge ≥92, Safari ≥15.4"
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {Array.from(new Array(numPages), (el, index) => {
                  const pageNum = index + 1;
                  const studentTextInfo = getStudentTextInfo(pageNum);
                  return (
                    <div
                      key={`page_${pageNum}`}
                      style={{ margin: "10px", textAlign: "center" }}
                    >
                      <p>
                        Seite {pageNum}
                        {studentTextInfo && (
                          <span style={{ color: "red" }}>
                            {" "}
                            (Aufsatz {
                              studentTextInfo.studentTextNumber
                            } Seite {studentTextInfo.pageInStudentText})
                          </span>
                        )}
                      </p>

                      <div
                        onClick={() => togglePageSelection(pageNum)}
                        style={{
                          height: "600px",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          overflow: "hidden",
                          border: selectedPages.includes(pageNum)
                            ? "5px solid blue"
                            : "none",
                        }}
                      >
                        <Page pageNumber={pageNum} height={600} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </Document>
            <div
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                backgroundColor: "#f0f0f0",
                padding: "10px",
                textAlign: "center",
                borderTop: "1px solid #ddd",
              }}
            >
              <Space>
                <p>
                  <strong>ausgewählte Seite(n) →</strong>{" "}
                  {selectedPages.length === 0 ? (
                    " - "
                  ) : (
                    <strong>{selectedPages.join(", ")}</strong>
                  )}
                </p>
                {!hasEnoughCredits ? (
                  <Tooltip
                    title="keine ausreichende Anzahl an zenpoints"
                    placement="top"
                  >
                    <Button onClick={showModal} disabled>
                      Auswahl beenden & fortfahren
                    </Button>
                  </Tooltip>
                ) : (
                  <Button onClick={showModal} type="primary">
                    Auswahl beenden & fortfahren
                  </Button>
                )}
              </Space>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PDFUploadPage;
