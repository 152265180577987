import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Tooltip,
  Input,
  InputNumber,
  Space,
  Table,
  Typography,
  Dropdown,
  Menu,
  Popconfirm,
  message,
  Radio,
  Tabs,
  Popover,
  Checkbox,
} from "antd";

import { CorrectionMarkDefinition } from "../components/CorrectionMarkDefinition"; // Import the new component

import { defaultCorrectionMarks } from "../components/CorrectionMarkDefinition";

import {
  DeleteOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const { Title, Text } = Typography;

export const gradesUnterstufe = [
  { grade: "1+", points: 15 },
  { grade: "1", points: 14 },
  { grade: "1-", points: 13 },
  { grade: "2+", points: 12 },
  { grade: "2", points: 11 },
  { grade: "2-", points: 10 },
  { grade: "3+", points: 9 },
  { grade: "3", points: 8 },
  { grade: "3-", points: 7 },
  { grade: "4+", points: 6 },
  { grade: "4", points: 5 },
  { grade: "4-", points: 4 },
  { grade: "5+", points: 3 },
  { grade: "5", points: 2 },
  { grade: "5-", points: 1 },
  { grade: "6", points: 0 },
];

export const gradesOberstufe = [
  { grade: "15", points: 15 },
  { grade: "14", points: 14 },
  { grade: "13", points: 13 },
  { grade: "12", points: 12 },
  { grade: "11", points: 11 },
  { grade: "10", points: 10 },
  { grade: "9", points: 9 },
  { grade: "8", points: 8 },
  { grade: "7", points: 7 },
  { grade: "6", points: 6 },
  { grade: "5", points: 5 },
  { grade: "4", points: 4 },
  { grade: "3", points: 3 },
  { grade: "2", points: 2 },
  { grade: "1", points: 1 },
  { grade: "0", points: 0 },
];

const PointComponent = ({
  userId,
  examId,
  taskNum,
  linguisticCriteriaName,
  contentCriteriaName,
  taskType,
  criteria,
  submissionData,
  ratings,
  suggestGrades,
  reportTechnicalIssue,
  loadingSuggestedGrades,
  ref4,
  ref6,
  getPercentageAchieved,
  createMarks,
  addCriterionToDB,
  MAX_CRITERIA,
  overallCommentLanguage,
  onChangeOverallCommentLanguage,
  changeOverallCommentLanguageInDB, // newly added prop
  ref7, // newly added prop
  overallCommentContent, // newly added prop
  onChangeOverallCommentContent, // newly added prop
  changeOverallCommentContentInDB, // newly added prop
  examData, // newly added prop
  onChangeCheckbox, // newly added prop
  setRatio, // newly added prop
  onChangeCriteriaDescription,
  changeCriterionInDB,
  onChangeRatingData,
  onChangeCriteriaPoints,
  deleteCriterion,
  onChangeHinweiseInDB,
  onChangeLobInDB,
  onChangeHinweiseContentInDB, // newly added prop
  onChangeLobContentInDB, // newly added prop
  onChangeAllgemeineAufgabenstellungInDB, // newly added prop
  annotations,
  // message,
}) => {
  let navigate = useNavigate();

  const clusterCategories =
    examId === "CQQ4YNsox6L7JMjmppUK"
      ? true
      : examData?.clusterCategories
      ? examData.clusterCategories
      : false;

  const gradesSystem =
    Array.isArray(examData?.grades) &&
    examData?.grades &&
    examData?.grades.length > 0 &&
    examData?.grades[0] &&
    examData?.grades[0]?.grade === "1+"
      ? gradesUnterstufe
      : gradesOberstufe;

  const [languageGrade, setLanguageGrade] = useState(0);
  const [contentGrade, setContentGrade] = useState(0);
  const [showFrequencies, setShowFrequencies] = useState(true);

  const [hinweise, setHinweise] = useState(
    submissionData?.tasks[parseInt(taskNum.replace("task", ""), 10) - 1]
      ?.hinweise
  );
  const [lob, setLob] = useState(
    submissionData?.tasks[parseInt(taskNum.replace("task", ""), 10) - 1]?.lob
  );

  const [allgemeineAufgabenstellung, setAllgemeineAufgabenstellung] =
    useState();
  // submissionData?.ratingsExplanations["8nz409OMDAmimz6n9H3e"]

  const [hinweiseContent, setHinweiseContent] = useState(
    submissionData?.tasks[parseInt(taskNum.replace("task", ""), 10) - 1]
      ?.hinweiseContent
  );
  const [lobContent, setLobContent] = useState(
    submissionData?.tasks[parseInt(taskNum.replace("task", ""), 10) - 1]
      ?.lobContent
  );

  // Add state for the issue description
  const [issueDescription, setIssueDescription] = useState("");

  // in case the hinweise or lob change in the database (after running suggestGrade), update the state
  useEffect(() => {
    const taskIndex = parseInt(taskNum.replace("task", ""), 10) - 1;
    const updatedHinweise = submissionData?.tasks[taskIndex]?.hinweise;
    const updatedLob = submissionData?.tasks[taskIndex]?.lob;
    const updatedHinweiseContent =
      submissionData?.tasks[taskIndex]?.hinweiseContent;
    const updatedLobContent = submissionData?.tasks[taskIndex]?.lobContent;
    // const updatedAllgemeineAufgabenstellung =
    //   submissionData?.ratingsExplanations["8nz409OMDAmimz6n9H3e"];
    const updatedAllgemeineAufgabenstellung = "";

    if (updatedHinweise !== hinweise) {
      setHinweise(updatedHinweise);
    }
    if (updatedLob !== lob) {
      setLob(updatedLob);
    }
    if (updatedHinweiseContent !== hinweiseContent) {
      setHinweiseContent(updatedHinweiseContent);
    }
    if (updatedLobContent !== lobContent) {
      setLobContent(updatedLobContent);
    }

    if (updatedAllgemeineAufgabenstellung !== allgemeineAufgabenstellung) {
      setAllgemeineAufgabenstellung(updatedAllgemeineAufgabenstellung);
    }
  }, [
    submissionData?.tasks[parseInt(taskNum.replace("task", ""), 10) - 1]
      ?.hinweise,
    submissionData?.tasks[parseInt(taskNum.replace("task", ""), 10) - 1]?.lob,
    submissionData?.tasks[parseInt(taskNum.replace("task", ""), 10) - 1]
      ?.hinweiseContent,
    submissionData?.tasks[parseInt(taskNum.replace("task", ""), 10) - 1]
      ?.lobContent,
    // submissionData?.ratingsExplanations["8nz409OMDAmimz6n9H3e"],
  ]);

  // Funktionen zum Berechnen der Noten basierend auf den Kriterien
  const calculateLanguageGrade = () => {
    // Implementieren Sie die Logik zur Berechnung der Sprachnote
    // und aktualisieren Sie den State mit setLanguageGrade
  };

  const calculateContentGrade = () => {
    // Implementieren Sie die Logik zur Berechnung der Inhaltsnote
    // und aktualisieren Sie den State mit setContentGrade
  };

  // Effekte, um die Noten zu berechnen, wenn sich die Kriterien ändern
  useEffect(() => {
    calculateLanguageGrade();
    calculateContentGrade();
  }, [criteria]); // Nehmen Sie an, dass `criteria` die Abhängigkeit ist, die die Neuberechnung auslöst

  // alle changes in inputs und textareas werden per event onblur in firestore gespeichert. This way blur() is manually run on all textreas / input fields before unloading the page to have all changes saved
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Select all textarea elements, including those wrapped by Ant Design's TextArea
      const elements = document.querySelectorAll(".ant-input");
      // Trigger blur on each element
      elements.forEach((element) => {
        if (element instanceof HTMLElement) {
          element.blur();
        }
      });
    };

    // Add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const renderDropdownForGrades = (elemPoints) => {
    const menu = (
      <Menu
        disabled={examId === "CQQ4YNsox6L7JMjmppUK"}
        onClick={(e) =>
          onChangeRatingData(elemPoints.id, { points: parseInt(e.key) })
        }
      >
        {gradesSystem.map((grade) => (
          <Menu.Item key={grade.points}>{grade.grade}</Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Space direction="horizonzal">
        <Dropdown
          disabled={
            examId === "CQQ4YNsox6L7JMjmppUK" || !submissionData.gradesSuggested
          }
          overlay={menu}
          trigger={["click"]}
          overlayStyle={{ maxHeight: "30vh", overflow: "auto" }}
        >
          <Button style={{ width: "50px" }}>
            {ratings && ratings.find((r) => r.id === elemPoints.id)
              ? `${
                  gradesSystem.find(
                    (g) =>
                      g.points ===
                      ratings.find((r) => r.id === elemPoints.id).points
                  )?.grade
                }`
              : "v"}
          </Button>
        </Dropdown>
        {ratings &&
          ratings.find((r) => r.id === elemPoints.id) &&
          ratings.find((r) => r.id === elemPoints.id).explanation &&
          elemPoints.id !== "8nz409OMDAmimz6n9H3e" && (
            <Tooltip
              title={
                ratings.find((r) => r.id === elemPoints.id).explanation || ""
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          )}
      </Space>
    );
  };

  const columns_table_content = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <Text
          style={{
            color: (() => {
              const correctionMark = defaultCorrectionMarks.find(
                (mark) =>
                  mark.category === "Inhalt" && mark.type === record.type
              );
              return correctionMark ? correctionMark.color : "#08F";
            })(),
            fontSize: "1.2em",
            fontFamily: "Times New Roman",
          }}
        >
          {text}
        </Text>
      ),
    },
    {
      title: "Bewertung",
      dataIndex: "type",
      key: "type",
      width: "10%",
    },
    {
      title: "Begründung",
      dataIndex: "reason",
      key: "reason",
      width: "70%",
    },
  ];

  const columns_table = [
    {
      title: "Beschreibung",
      dataIndex: "elemDescription",
      width: "85%",
      render: (elemDescription) => (
        // <TextArea
        //   // readOnly={examId === "CQQ4YNsox6L7JMjmppUK"}
        //   readOnly={true}
        //   disabled={true}
        //   autoSize={{ minRows: 1, maxRows: 2 }}
        //   value={elemDescription.description}
        //   // onChange={(e) =>
        //   //   onChangeCriteriaDescription(e.target.value, elemDescription.id)
        //   // }
        //   // onBlur={(e) =>
        //   //   changeCriterionInDB(elemDescription.id, {
        //   //     description: e.target.value,
        //   //   })
        //   // }
        //   maxLength={2048}
        // />
        <Text>{elemDescription.description}</Text>
      ),
    },
    {
      title:
        examData?.grades &&
        examData?.grades.length > 0 &&
        examData?.grades[0] &&
        examData?.grades[0]?.grade === "1+"
          ? "Note"
          : "Notenpunkte",
      dataIndex: "elemPoints",
      width: "5%",
      render: renderDropdownForGrades,
    },
    // {
    //   title: "Note",
    //   dataIndex: "elemPoints",
    //   width: "5%",
    //   render: (elemPoints) => (
    //     <InputNumber
    //       readOnly={examId === "CQQ4YNsox6L7JMjmppUK"}
    //       style={{ width: "80px" }} // Setzen Sie die Breite hier
    //       min={0}
    //       max={Math.round(elemPoints.points)}
    //       step={1}
    //       value={
    //         submissionData.ratings ? submissionData.ratings[elemPoints.id] : ""
    //       }
    //       onChange={(value) => onChangeCriteriaRating(value, elemPoints.id)}
    //       addonAfter={
    //         submissionData &&
    //         submissionData?.ratingsExplanations &&
    //         submissionData?.ratingsExplanations[elemPoints.id] && (
    //           <Tooltip
    //             title={
    //               submissionData?.ratingsExplanations
    //                 ? submissionData?.ratingsExplanations[elemPoints.id]
    //                 : ""
    //             }
    //           >
    //             <InfoCircleOutlined />
    //           </Tooltip>
    //         )
    //       }
    //     />
    //   ),
    // },
    taskType && taskType.includes("Anderer Aufgabentyp")
      ? {
          title: "Max",
          dataIndex: "elemMax",
          width: "5%",
          render: (elemMax) => (
            <InputNumber
              readOnly={examId === "CQQ4YNsox6L7JMjmppUK"}
              style={{ width: "50px" }} // Setzen Sie die Breite hier
              min={0}
              max={100}
              step={1}
              value={elemMax.points}
              onChange={(value) =>
                changeCriterionInDB(elemMax.id, { points: value })
              }
            />
          ),
        }
      : null,
    // examId !== "CQQ4YNsox6L7JMjmppUK"
    //   ? {
    //       title: "",
    //       dataIndex: "elemActions",
    //       width: "5%",
    //       render: (elemActions) => (
    //         <Popconfirm
    //           title="Bewertungskriterium entfernen"
    //           description="Sind Sie sicher, dass Sie dieses Kriterium entfernen möchten? Die Wiederherstellung ist nicht möglich."
    //           onConfirm={() => deleteCriterion(elemActions.id)}
    //           okText="Entfernen"
    //           cancelText="Abbrechen"
    //           icon={
    //             <QuestionCircleOutlined
    //               style={{
    //                 color: "red",
    //               }}
    //             />
    //           }
    //         >
    //           <Button
    //             disabled={examId === "CQQ4YNsox6L7JMjmppUK"}
    //             type="secondary"
    //             shape="circle"
    //             icon={<DeleteOutlined />}
    //           />
    //         </Popconfirm>
    //       ),
    //     }
    //   : null,
  ];

  const modifiedColumns = columns_table.filter(Boolean);

  // const calculateTotals = (criteria, submissionData) => {
  //   let totalPoints = 0;
  //   let totalMax = 0;

  //   criteria.forEach((criterion) => {
  //     const criterionId = criterion.id;
  //     const criterionPoints = parseInt(criterion.points);
  //     // Ensure that points are a number and default to 0 if not
  //     const points =
  //       submissionData.ratings && !isNaN(submissionData.ratings[criterionId])
  //         ? submissionData.ratings[criterionId]
  //         : 0;
  //     // Ensure that criterion.points is a number and default to 0 if not
  //     totalPoints += points;
  //     totalMax += !isNaN(criterionPoints) ? criterionPoints : 0;
  //   });

  //   return { totalPoints, totalMax };
  // };

  // const onChangeRadio = (e) => {
  //   setClusterCategories(e.target.value);
  // };

  const contentAnnotationsInhaltlicherAufbau = annotations.filter(
    (annotation) =>
      annotation.category === "content" &&
      annotation.contentType === "Inhaltlicher Aufbau"
  );

  const contentAnnotationsUmgangMitDemThema = annotations.filter(
    (annotation) =>
      annotation.category === "content" &&
      annotation.contentType === "Umgang mit dem Thema"
  );

  const fieldKeys = [
    {
      description: "",
      name: "userid",
      qid: "127",
      subLabel: "",
      text: "userid",
      type: "control_textbox",
    },
    {
      description: "",
      name: "examid",
      qid: "128",
      subLabel: "",
      text: "examid",
      type: "control_textbox",
    },
    {
      description: "",
      name: "nameLehrkraft",
      qid: "145",
      subLabel: "wird auf dem Bewertungsbogen angezeigt",
      text: "Name der Lehrkraft",
      type: "control_textbox",
    },
    {
      description: "",
      name: "titel",
      qid: "32",
      subLabel: "wird auf dem Bewertungsbogen angezeigt",
      text: "Titel",
      type: "control_textbox",
    },
    {
      description: "",
      name: "fach",
      qid: "35",
      subLabel: "",
      text: "Fach",
      type: "control_dropdown",
    },
    {
      description: "",
      name: "sprache",
      qid: "38",
      subLabel: "",
      text: "Sprache",
      type: "control_dropdown",
    },
    {
      description: "",
      name: "klasse",
      qid: "36",
      subLabel: "",
      text: "Klasse",
      type: "control_dropdown",
    },
    {
      description: "",
      name: "notenskala",
      qid: "37",
      subLabel: "",
      text: "Notenskala",
      type: "control_dropdown",
    },
    {
      description: "",
      name: "wasSoll",
      qid: "42",
      text: "Was soll bewertet werden?",
      type: "control_checkbox",
    },
    {
      name: "schreibenSie",
      qid: "134",
      text: "Gewichtung Sprache an Gesamtwertung in %",
      type: "control_widget",
    },
    {
      description: "",
      name: "sprachniveau",
      qid: "47",
      subLabel: "",
      text: "Sprachniveau",
      type: "control_dropdown",
    },
    {
      description: "",
      name: "lernjahrDer",
      qid: "51",
      subLabel: "Grundschuljahre nicht mitzahlen",
      text: "Lernjahr der Fremdsprache",
      type: "control_dropdown",
    },
    {
      description: "",
      name: "textsorte",
      qid: "50",
      subLabel: "",
      text: "Textsorte",
      type: "control_textbox",
    },
    {
      description: "",
      name: "merkmaleTextsorte",
      qid: "149",
      subLabel: "",
      text: "Sprachliche Gestaltung der Textsorte",
      type: "control_textarea",
    },
    {
      description: "",
      name: "charakteristischeElemente",
      qid: "157",
      subLabel: "",
      text: "charakteristische Elemente der Textsorte",
      type: "control_textarea",
    },
    {
      description: "",
      name: "stil",
      qid: "52",
      subLabel: "",
      text: "Stil",
      type: "control_dropdown",
    },
    {
      description: "",
      name: "zeitform",
      qid: "53",
      subLabel: "Hier maximal eine Zeitform hinterlegen",
      text: "Zeitform",
      type: "control_textbox",
    },
    {
      description: "",
      name: "zitierweise",
      qid: "54",
      subLabel: "",
      text: "Zitierweise",
      type: "control_textbox",
    },
    {
      description: "",
      name: "mindWortanzahl",
      qid: "55",
      subLabel: "",
      text: "mind. Wortanzahl",
      type: "control_number",
    },
    {
      description: "",
      mde: "No",
      name: "erwartungenWortschatz",
      qid: "57",
      subLabel:
        "Fur diese Worte erhalt der Schuler eine positive Ruckmeldung - kein Pflichtfeld",
      text: "Erwartungen Wortschatz",
      type: "control_textarea",
      wysiwyg: "Disable",
    },
    {
      name: "musterlosungFur",
      text: "Musterlösung für die Bestnote",
    },
    {
      description: "",
      name: "kriteriumLexik66",
      qid: "66",
      text: "Lexik: Korrektheit",
      type: "control_checkbox",
    },
    {
      description: "",
      name: "lexikBandbreite",
      qid: "71",
      text: "Lexik: Bandbreite",
      type: "control_checkbox",
    },
    {
      description: "",
      name: "lexikKorrektheit73",
      qid: "73",
      text: "Grammatik: Korrektheit",
      type: "control_checkbox",
    },
    {
      description: "",
      name: "lexikKorrektheit75",
      qid: "75",
      text: "Grammatik: Bandbreite",
      type: "control_checkbox",
    },
    {
      description: "",
      name: "lexikKorrektheit77",
      qid: "77",
      text: "Textgestaltung: Kohäsion",
      type: "control_checkbox",
    },
    {
      description: "",
      name: "textgestaltungTextsorte",
      qid: "142",
      text: "Textgestaltung: Textsorte",
      type: "control_checkbox",
    },
    {
      description: "",
      name: "hinweistexteZur138",
      qid: "138",
      text: "Hinweistexte zur Verbesserung und positives Feedback",
      type: "control_checkbox",
    },
    {
      description: "",
      name: "fur",
      qid: "137",
      text: "für",
      type: "control_radio",
    },
    {
      description: "",
      mde: "No",
      name: "aufgabenstellung",
      qid: "81",
      subLabel: "",
      text: "Aufgabenstellung",
      type: "control_textarea",
      wysiwyg: "Disable",
    },
    {
      description: "",
      mde: "No",
      name: "material",
      qid: "82",
      subLabel: "",
      text: "Material",
      type: "control_textarea",
      wysiwyg: "Disable",
    },
    {
      description: "",
      name: "textsorte84",
      qid: "84",
      subLabel: "",
      text: "Textsorte",
      type: "control_textbox",
    },
    {
      description: "",
      name: "operator",
      qid: "85",
      subLabel: "",
      text: "Operator",
      type: "control_textbox",
    },
    {
      description: "",
      name: "operatorBedeutung",
      qid: "147",
      subLabel: "",
      text: "Operator Bedeutung",
      type: "control_textarea",
    },
    {
      description: "",
      name: "mindWortanzahl",
      text: "mind. Wortanzahl",
    },
    {
      description: "",
      name: "titel89",
      qid: "89",
      subLabel: "",
      text: "Titel",
      type: "control_textbox",
    },
    {
      description: "",
      name: "schreibenSie90",
      qid: "90",
      subLabel: "",
      text: "Autor",
      type: "control_textbox",
    },
    {
      description: "",
      name: "schreibenSie91",
      qid: "91",
      subLabel: "",
      text: "Erscheinungsjahr",
      type: "control_textbox",
    },
    {
      description: "",
      mde: "No",
      name: "vorwissen",
      qid: "94",
      subLabel: "",
      text: "Vorwissen",
      type: "control_textarea",
      wysiwyg: "Disable",
    },
    {
      description: "",
      name: "uberschrift",
      qid: "99",
      text: "Überschrift",
      type: "control_checkbox",
    },
    // {
    //   description: "",
    //   name: "erwartungenUberschrift",
    //   qid: "101",
    //   subLabel: "",
    //   text: "Erwartungen Überschrift",
    //   type: "control_textbox",
    // },
    {
      description: "",
      name: "erwartungenUberschrift",
      qid: "155",
      subLabel: "",
      text: "Erwartungen Überschrift",
      type: "control_textarea",
    },
    {
      description: "",
      name: "einleitung",
      qid: "102",
      text: "Einleitung",
      type: "control_checkbox",
    },
    // {
    //   description: "",
    //   name: "erwartungenEinleitung",
    //   qid: "103",
    //   subLabel: "",
    //   text: "Erwartungen Einleitung",
    //   type: "control_textbox",
    // },
    {
      description: "",
      name: "erwartungenEinleitung",
      qid: "154",
      subLabel: "",
      text: "Erwartungen Einleitung",
      type: "control_textarea",
    },
    {
      description: "",
      mde: "No",
      name: "erwartungenHauptteil",
      qid: "108",
      subLabel:
        "ausfuhrliche Erwartungen zur Aufgabenerfullung \u002F mogliche Aspekte hinterlegen",
      text: "Inhaltliche Erwartungen Hauptteil",
      type: "control_textarea",
      wysiwyg: "Disable",
    },
    {
      description: "",
      name: "erwartungenHauptteilZusatz",
      qid: "151",
      text: "Erwartungen Hauptteil Zusatz",
      type: "control_checkbox",
    },
    {
      description: "",
      name: "schluss",
      qid: "106",
      text: "Schluss",
      type: "control_checkbox",
    },
    // {
    //   description: "",
    //   name: "erwartungenSchluss",
    //   qid: "107",
    //   subLabel: "",
    //   text: "Erwartungen Schluss",
    //   type: "control_textbox",
    // },
    {
      description: "",
      name: "erwartungenSchluss",
      qid: "156",
      subLabel: "",
      text: "Erwartungen Schluss",
      type: "control_textarea",
    },
    {
      description: "",
      mde: "No",
      name: "musterlosungFur",
      qid: "58",
      subLabel: "kein Pflichtfeld",
      text: "Musterlösung für die Bestnote",
      type: "control_textarea",
      wysiwyg: "Disable",
    },
    {
      description: "",
      name: "allgemeineAufgabenerfullung",
      qid: "112",
      text: "Allgemeine Aufgabenerfüllung gemäß Operator",
      type: "control_checkbox",
    },
    {
      description: "",
      name: "umgangMit",
      qid: "114",
      text: "Inhaltliche Erschließung (Umgang mit dem Thema)",
      type: "control_checkbox",
    },
    {
      description: "",
      name: "inhaltlicherAufbau124",
      qid: "124",
      text: "Inhaltlicher Aufbau (Struktur, Stringenz, Kohärenz)",
      type: "control_checkbox",
    },
    {
      name: "hinweistexteZur",
      text: "Hinweistexte zur Verbesserung und positives Feedback",
    },
    {
      name: "fur140",
      text: "für",
    },
  ];

  // const fieldKeys = [
  //   null,
  //   {
  //     name: "clickTo",
  //     qid: "1",
  //     text: "Erwartungshorizont",
  //     type: "control_head",
  //   },
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   { name: "divider29", qid: "29", text: "Divider", type: "control_divider" },
  //   null,
  //   null,
  //   {
  //     description: "",
  //     name: "titel",
  //     qid: "32",
  //     subLabel: "wird auf dem Bewertungsbogen angezeigt",
  //     text: "Titel",
  //     type: "control_textbox",
  //   },
  //   null,
  //   null,
  //   {
  //     description: "",
  //     name: "fach",
  //     qid: "35",
  //     subLabel: "",
  //     text: "Fach",
  //     type: "control_dropdown",
  //   },
  //   {
  //     description: "",
  //     name: "klasse",
  //     qid: "36",
  //     subLabel: "",
  //     text: "Klasse",
  //     type: "control_dropdown",
  //   },
  //   {
  //     description: "",
  //     name: "notenskala",
  //     qid: "37",
  //     subLabel: "",
  //     text: "Notenskala",
  //     type: "control_dropdown",
  //   },
  //   {
  //     description: "",
  //     name: "sprache",
  //     qid: "38",
  //     subLabel: "",
  //     text: "Sprache",
  //     type: "control_dropdown",
  //   },
  //   {
  //     description: "",
  //     name: "sonstigesFach",
  //     qid: "39",
  //     subLabel: "",
  //     text: "Sonstiges Fach",
  //     type: "control_textbox",
  //   },
  //   {
  //     name: "seitenumbruch",
  //     qid: "40",
  //     text: "Seitenumbruch",
  //     type: "control_pagebreak",
  //   },
  //   {
  //     name: "freitextaufgabe",
  //     qid: "41",
  //     text: "Freitextaufgabe",
  //     type: "control_head",
  //   },
  //   {
  //     description: "",
  //     name: "wasSoll",
  //     qid: "42",
  //     text: "Was soll bewertet werden?",
  //     type: "control_checkbox",
  //   },
  //   {
  //     name: "seitenumbruch43",
  //     qid: "43",
  //     text: "Seitenumbruch",
  //     type: "control_pagebreak",
  //   },
  //   { name: "sprache44", qid: "44", text: "Sprache", type: "control_head" },
  //   {
  //     name: "seitenumbruch45",
  //     qid: "45",
  //     text: "Seitenumbruch",
  //     type: "control_pagebreak",
  //   },
  //   { name: "inhalt", qid: "46", text: "Inhalt", type: "control_head" },
  //   {
  //     description: "",
  //     name: "sprachniveau",
  //     qid: "47",
  //     subLabel: "",
  //     text: "Sprachniveau",
  //     type: "control_dropdown",
  //   },
  //   null,
  //   null,
  //   {
  //     description: "",
  //     name: "textsorte",
  //     qid: "50",
  //     subLabel: "",
  //     text: "Textsorte",
  //     type: "control_textbox",
  //   },
  //   {
  //     description: "",
  //     name: "lernjahrDer",
  //     qid: "51",
  //     subLabel: "Grundschuljahre nicht mitzahlen",
  //     text: "Lernjahr der Fremdsprache",
  //     type: "control_dropdown",
  //   },
  //   {
  //     description: "",
  //     name: "stil",
  //     qid: "52",
  //     subLabel: "",
  //     text: "Stil",
  //     type: "control_dropdown",
  //   },
  //   {
  //     description: "",
  //     name: "zeitform",
  //     qid: "53",
  //     subLabel: "Hier maximal eine Zeitform hinterlegen",
  //     text: "Zeitform",
  //     type: "control_textbox",
  //   },
  //   {
  //     description: "",
  //     name: "zitierweise",
  //     qid: "54",
  //     subLabel: "",
  //     text: "Zitierweise",
  //     type: "control_textbox",
  //   },
  //   {
  //     description: "",
  //     name: "mindWortanzahl",
  //     qid: "55",
  //     subLabel: "",
  //     text: "mind. Wortanzahl",
  //     type: "control_number",
  //   },
  //   null,
  //   {
  //     description: "",
  //     mde: "No",
  //     name: "erwartungenWortschatz",
  //     qid: "57",
  //     subLabel:
  //       "Fur diese Worte erhalt der Schuler eine positive Ruckmeldung - kein Pflichtfeld",
  //     text: "Erwartungen Wortschatz",
  //     type: "control_textarea",
  //     wysiwyg: "Disable",
  //   },
  //   {
  //     description: "",
  //     mde: "No",
  //     name: "musterlosungFur",
  //     qid: "58",
  //     subLabel: "kein Pflichtfeld",
  //     text: "Musterlosung fur die Bestnote",
  //     type: "control_textarea",
  //     wysiwyg: "Disable",
  //   },
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   {
  //     description: "",
  //     name: "kriteriumLexik66",
  //     qid: "66",
  //     text: "Lexik: Korrektheit",
  //     type: "control_checkbox",
  //   },
  //   null,
  //   {
  //     name: "seitenumbruch68",
  //     qid: "68",
  //     text: "Seitenumbruch",
  //     type: "control_pagebreak",
  //   },
  //   {
  //     name: "bewertungskriterien",
  //     qid: "69",
  //     text: "Bewertungskriterien",
  //     type: "control_head",
  //   },
  //   null,
  //   {
  //     description: "",
  //     name: "lexikBandbreite",
  //     qid: "71",
  //     text: "Lexik: Bandbreite",
  //     type: "control_checkbox",
  //   },
  //   null,
  //   {
  //     description: "",
  //     name: "lexikKorrektheit73",
  //     qid: "73",
  //     text: "Grammatik: Korrektheit",
  //     type: "control_checkbox",
  //   },
  //   null,
  //   {
  //     description: "",
  //     name: "lexikKorrektheit75",
  //     qid: "75",
  //     text: "Grammatik: Bandbreite",
  //     type: "control_checkbox",
  //   },
  //   null,
  //   {
  //     description: "",
  //     name: "lexikKorrektheit77",
  //     qid: "77",
  //     text: "Textgestaltung: Kohasion",
  //     type: "control_checkbox",
  //   },
  //   null,
  //   null,
  //   null,
  //   {
  //     description: "",
  //     mde: "No",
  //     name: "aufgabenstellung",
  //     qid: "81",
  //     subLabel: "",
  //     text: "Aufgabenstellung",
  //     type: "control_textarea",
  //     wysiwyg: "Disable",
  //   },
  //   {
  //     description: "",
  //     mde: "No",
  //     name: "material",
  //     qid: "82",
  //     subLabel: "",
  //     text: "Material",
  //     type: "control_textarea",
  //     wysiwyg: "Disable",
  //   },
  //   {
  //     name: "erwartungshorizontFur",
  //     qid: "83",
  //     text: "Erwartungshorizont fur die Aufgabenerfullung",
  //     type: "control_head",
  //   },
  //   {
  //     description: "",
  //     name: "textsorte84",
  //     qid: "84",
  //     subLabel: "",
  //     text: "Textsorte",
  //     type: "control_textbox",
  //   },
  //   {
  //     description: "",
  //     name: "operator",
  //     qid: "85",
  //     subLabel: "",
  //     text: "Operator",
  //     type: "control_textbox",
  //   },
  //  {
  //     description: "",
  //     name: "operatorBedeutung",
  //     qid: "147",
  //     subLabel: "",
  //     text: "Operator Bedeutung",
  //     type: "control_textarea",
  //   },
  //   {
  //     description: "",
  //     name: "mindWortanzahl87",
  //     qid: "87",
  //     subLabel: "",
  //     text: "mind. Wortanzahl",
  //     type: "control_number",
  //   },
  //   null,
  //   {
  //     description: "",
  //     name: "titel89",
  //     qid: "89",
  //     subLabel: "",
  //     text: "Titel",
  //     type: "control_textbox",
  //   },
  //   {
  //     description: "",
  //     name: "schreibenSie90",
  //     qid: "90",
  //     subLabel: "",
  //     text: "Autor",
  //     type: "control_textbox",
  //   },
  //   {
  //     description: "",
  //     name: "schreibenSie91",
  //     qid: "91",
  //     subLabel: "",
  //     text: "Erscheinungsjahr",
  //     type: "control_textbox",
  //   },
  //   {
  //     name: "trennlinie",
  //     qid: "92",
  //     text: "Trennlinie",
  //     type: "control_divider",
  //   },
  //   { name: "lekture93", qid: "93", text: "Lekture", type: "control_head" },
  //   {
  //     description: "",
  //     mde: "No",
  //     name: "vorwissen",
  //     qid: "94",
  //     subLabel: "",
  //     text: "Vorwissen",
  //     type: "control_textarea",
  //     wysiwyg: "Disable",
  //   },
  //   {
  //     name: "trennlinie95",
  //     qid: "95",
  //     text: "Trennlinie",
  //     type: "control_divider",
  //   },
  //   {
  //     name: "inhaltlicherAufbau",
  //     qid: "96",
  //     text: "Inhaltlicher Aufbau & Umgang mit dem Thema ",
  //     type: "control_head",
  //   },
  //   {
  //     name: "trennlinie97",
  //     qid: "97",
  //     text: "Trennlinie",
  //     type: "control_divider",
  //   },
  //   null,
  //   {
  //     description: "",
  //     name: "uberschrift",
  //     qid: "99",
  //     text: "Uberschrift",
  //     type: "control_checkbox",
  //   },
  //   null,
  //   {
  //     description: "",
  //     name: "erwartungenUberschrift",
  //     qid: "101",
  //     subLabel: "",
  //     text: "Erwartungen Uberschrift",
  //     type: "control_textbox",
  //   },
  //   {
  //     description: "",
  //     name: "einleitung",
  //     qid: "102",
  //     text: "Einleitung",
  //     type: "control_checkbox",
  //   },
  //   {
  //     description: "",
  //     name: "erwartungenEinleitung",
  //     qid: "103",
  //     subLabel: "",
  //     text: "Erwartungen Einleitung",
  //     type: "control_textbox",
  //   },
  //   null,
  //   null,
  //   {
  //     description: "",
  //     name: "schluss",
  //     qid: "106",
  //     text: "Schluss",
  //     type: "control_checkbox",
  //   },
  //   {
  //     description: "",
  //     name: "erwartungenSchluss",
  //     qid: "107",
  //     subLabel: "",
  //     text: "Erwartungen Schluss",
  //     type: "control_textbox",
  //   },
  //   {
  //     description: "",
  //     mde: "No",
  //     name: "erwartungenHauptteil",
  //     qid: "108",
  //     subLabel:
  //       "ausfuhrliche Erwartungen zur Aufgabenerfullung \u002F mogliche Aspekte hinterlegen",
  //     text: "Erwartungen Hauptteil",
  //     type: "control_textarea",
  //     wysiwyg: "Disable",
  //   },
  //   {
  //     description: "",
  //     mde: "No",
  //     name: "musterlosungFur109",
  //     qid: "109",
  //     subLabel: "kein Pflichtfeld",
  //     text: "Musterlosung fur die Bestnote",
  //     type: "control_textarea",
  //     wysiwyg: "Disable",
  //   },
  //   {
  //     name: "bewertungskriterien110",
  //     qid: "110",
  //     text: "Bewertungskriterien",
  //     type: "control_head",
  //   },
  //   null,
  //   {
  //     description: "",
  //     name: "allgemeineAufgabenerfullung",
  //     qid: "112",
  //     text: "Allgemeine Aufgabenerfullung gemaß Operator",
  //     type: "control_checkbox",
  //   },
  //   null,
  //   {
  //     description: "",
  //     name: "umgangMit",
  //     qid: "114",
  //     text: "Inhaltliche Erschließung (Umgang mit dem Thema)",
  //     type: "control_checkbox",
  //   },
  //   null,
  //   null,
  //   {
  //     name: "seitenumbruch117",
  //     qid: "117",
  //     text: "Seitenumbruch",
  //     type: "control_pagebreak",
  //   },
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   {
  //     description: "",
  //     name: "inhaltlicherAufbau124",
  //     qid: "124",
  //     text: "Inhaltlicher Aufbau (Struktur, Stringenz, Koharenz)",
  //     type: "control_checkbox",
  //   },
  //   null,
  //   {
  //     name: "prufungerstellen",
  //     qid: "126",
  //     text: "Prufung erstellen",
  //     type: "control_button",
  //   },
  //   {
  //     description: "",
  //     name: "userid",
  //     qid: "127",
  //     subLabel: "",
  //     text: "userid",
  //     type: "control_textbox",
  //   },
  //   {
  //     description: "",
  //     name: "examid",
  //     qid: "128",
  //     subLabel: "",
  //     text: "examid",
  //     type: "control_textbox",
  //   },
  //   {
  //     name: "seitenumbruch129",
  //     qid: "129",
  //     text: "Seitenumbruch",
  //     type: "control_pagebreak",
  //   },
  //   null,
  //   null,
  //   null,
  //   null,
  //   {
  //     name: "schreibenSie",
  //     qid: "134",
  //     text: "Gewichtung Sprache an Gesamtwertung in %",
  //     type: "control_widget",
  //   },
  //   null,
  //   {
  //     name: "input136",
  //     qid: "136",
  //     text: "Die ubrigen Prozente werden dem Inhalt zugeordet.",
  //     type: "control_text",
  //   },
  //   {
  //     description: "",
  //     name: "fur",
  //     qid: "137",
  //     text: "fur",
  //     type: "control_radio",
  //   },
  //   {
  //     description: "",
  //     name: "hinweistexteZur138",
  //     qid: "138",
  //     text: "Hinweistexte zur Verbesserung und positives Feedback",
  //     type: "control_checkbox",
  //   },
  //   {
  //     description: "",
  //     name: "hinweistexteZur",
  //     qid: "139",
  //     text: "Hinweistexte zur Verbesserung und positives Feedback",
  //     type: "control_checkbox",
  //   },
  //   {
  //     description: "",
  //     name: "fur140",
  //     qid: "140",
  //     text: "fur",
  //     type: "control_radio",
  //   },
  //   {
  //     name: "trennlinie141",
  //     qid: "141",
  //     text: "Trennlinie",
  //     type: "control_divider",
  //   },
  //   {
  //     description: "",
  //     name: "textgestaltungTextsorte",
  //     qid: "142",
  //     text: "Textgestaltung: Textsorte",
  //     type: "control_checkbox",
  //   },
  // ];

  function stripHTML(html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  const [localExplanations, setLocalExplanations] = useState({});

  useEffect(() => {
    const initialExplanations = {};
    ratings.forEach((rating) => {
      initialExplanations[rating.id] = rating.explanation || "";
    });
    setLocalExplanations(initialExplanations);
  }, [ratings]);

  const renderLinguisticCriteriaTable = () => (
    <>
      {criteria
        .filter((criterion) => criterion.type === "linguistic")
        .sort((a, b) => a.order - b.order)
        .map((criterion) => {
          const explanation = localExplanations[criterion.id] || "";
          if (!explanation.trim()) return null; // Skip rendering if the textarea is empty
          return (
            <div key={criterion.id} style={{ marginBottom: "10px" }}>
              <Title level={5}>{criterion.description}</Title>
              <TextArea
                value={explanation}
                onChange={(e) => {
                  setLocalExplanations((prev) => ({
                    ...prev,
                    [criterion.id]: e.target.value,
                  }));
                }}
                onBlur={(e) => {
                  onChangeRatingData(criterion.id, {
                    explanation: e.target.value,
                  });
                }}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </div>
          );
        })}
    </>
  );

  const tabItems = [
    {
      key: "2",
      label: "Korrekturzeichen",
      children: (
        <>
          <Space direction="vertical">
            {submissionData.hint && (
              <small>Hinweis: {submissionData.hint}</small>
            )}
            <Checkbox
              defaultChecked
              onChange={(e) => setShowFrequencies(e.target.checked)}
            >
              Fehler zählen
            </Checkbox>
            <Text>
              {
                submissionData?.tasks[
                  parseInt(taskNum.replace("task", ""), 10) - 1
                ]?.convertedText.split(" ").length
              }{" "}
              Wörter
            </Text>
          </Space>

          <CorrectionMarkDefinition
            open={true}
            examData={examData}
            examIdProp={examId}
            annotations={annotations}
            showFrequencies={showFrequencies}
          />
        </>
      ),
    },
    examData.formData?.wassoll &&
    examData.formData?.wassoll.join().includes("Sprache")
      ? {
          key: "3",
          label: "Sprache",
          children: (
            <>
              {examData.formData?.wassoll &&
                examData.formData?.wassoll.join().includes("Sprache") && (
                  <div ref={ref4}>
                    {!submissionData.gradesSuggested &&
                      examData.formData?.wassoll &&
                      (examData.formData?.wassoll.join().includes("Sprache") ||
                        examData.formData?.wassoll
                          .join()
                          .includes("Inhalt")) && (
                        <>
                          <Popconfirm
                            title="Notenvorschläge"
                            description="Bitte stellen Sie sicher, dass Sie die sprachliche Korrektur abgeschlossen und somit auch die Transkription überprüft haben, bevor Sie fortfahren. Anpassungen, die für die Benotung berücksichtigt werden sollen, sind danach nicht mehr möglich."
                            onConfirm={() => {
                              message.info({
                                content:
                                  "Noten werden ermittelt. Dies kann mehrere Minuten in Anspruch nehmen.",
                                duration: 10,
                                onClick: () => message.destroy(),
                              });
                              suggestGrades();
                            }}
                            okText="Fortfahren"
                            cancelText="Abbrechen"
                            overlayStyle={{ maxWidth: "300px" }}
                          >
                            <Button
                              ref={ref6}
                              type="primary"
                              shape="round"
                              loading={
                                loadingSuggestedGrades ||
                                submissionData?.gradesLoading
                              }
                            >
                              Noten vorschlagen
                            </Button>
                          </Popconfirm>
                        </>
                      )}
                    {/* {submissionData.gradesSuggested && (
                      <Popover
                        content="Aktuell erhalten wir inkonsistente Antworten von Microsoft Azure. Wenn die Korrekturzeichen oder Begründungstexte nicht korrekt platziert sind oder nicht nachvollziehbar erscheinen, klicken Sie bitte auf diesen Button, um uns zu informieren. Wir analysieren und beheben den Fehler schnellstmöglich. Sobald ein neuer Notenvorschlag für diesen Aufsatz erstellt wurde, erhalten Sie eine Benachrichtigung."
                        title="Technischen Fehler melden"
                        overlayStyle={{ maxWidth: "300px" }}
                      >
                        <Popconfirm
                          title="Technischen Fehler melden"
                          description={
                            <div>
                              <p>
                                Bitte beschreiben Sie kurz das Problem:{" "}
                                <span style={{ color: "red" }}>*</span>
                              </p>
                              <TextArea
                                value={issueDescription}
                                onChange={(e) =>
                                  setIssueDescription(e.target.value)
                                }
                                // placeholder="z.B. Korrekturzeichen falsch platziert, Begründungstexte unpassend..."
                                autoSize={{ minRows: 2, maxRows: 4 }}
                                status={!issueDescription.trim() ? "error" : ""}
                                required
                              />
                              {!issueDescription.trim() && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "4px",
                                  }}
                                >
                                  <small>
                                    Bitte geben Sie eine Beschreibung ein
                                  </small>
                                </div>
                              )}
                              <p>
                                <small>
                                  Wir analysieren und beheben den Fehler
                                  schnellstmöglich. Sobald ein neuer
                                  Notenvorschlag für diesen Aufsatz erstellt
                                  wurde, erhalten Sie eine Benachrichtigung.
                                </small>
                              </p>
                            </div>
                          }
                          onConfirm={() => {
                            if (!issueDescription.trim()) {
                              message.error(
                                "Bitte geben Sie eine kurze Beschreibung des Problems ein."
                              );
                              return false; // Prevents the Popconfirm from closing
                            }
                            reportTechnicalIssue(issueDescription);
                            setIssueDescription(""); // Reset after submission
                          }}
                          okText="Melden"
                          cancelText="Abbrechen"
                          overlayStyle={{ maxWidth: "300px" }}
                        >
                          <Button
                            type="primary"
                            shape="round"
                            style={{
                              backgroundColor: "orange",
                              borderColor: "orange",
                            }}
                          >
                            Technischen Fehler melden
                          </Button>
                        </Popconfirm>
                      </Popover>
                    )} */}
                    {submissionData &&
                      submissionData?.tasks[
                        parseInt(taskNum.replace("task", ""), 10)
                      ]?.cefrLevel && (
                        <small>{`Das CEFR Level des Textes ist ${
                          submissionData?.tasks[
                            parseInt(taskNum.replace("task", ""), 10)
                          ]?.cefrLevel
                        }.`}</small>
                      )}
                    <Title level={5}>
                      {/* {linguisticCriteriaName
                    ? linguisticCriteriaName
                    : "sprachliche Bewertungskriterien"} */}
                      Sprachliche Bewertungskriterien
                    </Title>
                    {criteria ? (
                      <>
                        {/* <Radio.Group
                          // onChange={onChangeRadio}
                          value={clusterCategories}
                          disabled
                        >
                          <Radio value={true}>
                            Noten in Unterkategorien bilden
                          </Radio>
                          <Radio value={false}>
                            keine Noten in Unterkategorien bilden
                          </Radio>
                        </Radio.Group>
                        <br />
                        <br /> */}
                        <Table
                          dataSource={criteria
                            .filter(
                              (criterion) => criterion.type === "linguistic"
                            )
                            .sort((a, b) => a.order - b.order)
                            .map((criterion) => {
                              return {
                                elemDescription: criterion,
                                elemPoints: criterion,
                                elemMax: criterion,
                                elemActions: criterion,
                              };
                            })}
                          columns={modifiedColumns}
                          locale={{
                            emptyText: "keine Bewertungskriterien gesetzt",
                          }}
                          showHeader={true}
                          pagination={false}
                          size="small"
                          summary={() => {
                            const linguisticCriteria = criteria.filter(
                              (criterion) => criterion.type === "linguistic"
                            );

                            let averagePoints;
                            let categoryAverages;

                            if (!clusterCategories) {
                              let totalPoints = 0;
                              linguisticCriteria.forEach((criterion) => {
                                const criterionId = criterion.id;
                                const points =
                                  ratings.find((r) => r.id === criterionId)
                                    ?.points || 0;
                                totalPoints += points;
                              });
                              averagePoints = (
                                totalPoints / linguisticCriteria.length
                              ).toFixed(0);

                              setLanguageGrade(averagePoints);
                            } else {
                              // SPRACHLICHE BEWERTUNGSKRITERIEN - alle kriterien gleich gewichtet

                              // Gruppieren der Kriterien nach Unterkategorien
                              const categories = linguisticCriteria.reduce(
                                (acc, criterion) => {
                                  const category =
                                    criterion.description.split(": ")[0]; // Extrahieren der Unterkategorie
                                  if (!acc[category]) {
                                    acc[category] = [];
                                  }
                                  acc[category].push(criterion);
                                  return acc;
                                },
                                {}
                              );

                              // Berechnen der durchschnittlichen Punkte für jede Unterkategorie
                              categoryAverages = Object.keys(categories).map(
                                (category) => {
                                  const criteria = categories[category];
                                  let totalPoints = 0;
                                  criteria.forEach((criterion) => {
                                    const criterionId = criterion.id;
                                    const rating = ratings.find(
                                      (r) => r.id === criterionId
                                    );
                                    const points =
                                      rating && !isNaN(rating.points)
                                        ? rating.points
                                        : 0;
                                    totalPoints += points;
                                  });
                                  const averagePoints = (
                                    totalPoints / criteria.length
                                  ).toFixed(0);
                                  return {
                                    category,
                                    averagePoints,
                                  };
                                }
                              );

                              averagePoints =
                                categoryAverages.reduce(
                                  (acc, c) => acc + parseInt(c.averagePoints),
                                  0
                                ) / categoryAverages.length;
                              averagePoints = averagePoints.toFixed(0);

                              setLanguageGrade(averagePoints);
                            }

                            const contentCriteria = criteria.filter(
                              (criterion) => criterion.type === "content"
                            );

                            let averagePointsContent;
                            let totalPoints = 0;
                            contentCriteria.forEach((criterion) => {
                              const criterionId = criterion.id;
                              const points =
                                ratings.find((r) => r.id === criterionId)
                                  ?.points || 0;
                              totalPoints += points;
                            });
                            averagePointsContent = (
                              totalPoints / contentCriteria.length
                            ).toFixed(0);

                            setContentGrade(averagePointsContent);
                            return (
                              <>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell index={0}>
                                    {!clusterCategories ? (
                                      <Text>Note Sprache: ⌀ Einzelnoten</Text>
                                    ) : (
                                      <Text>
                                        Note Sprache: ⌀ aus Gesamtnoten für
                                        Lexik (
                                        {gradesSystem.find(
                                          (g) =>
                                            g.points ===
                                            parseInt(
                                              categoryAverages.find(
                                                (c) => c.category === "Lexik"
                                              )?.averagePoints || 0
                                            )
                                        )?.grade || "0"}
                                        ) Grammatik (
                                        {gradesSystem.find(
                                          (g) =>
                                            g.points ===
                                            parseInt(
                                              categoryAverages.find(
                                                (c) =>
                                                  c.category === "Grammatik"
                                              )?.averagePoints || 0
                                            )
                                        )?.grade || "0"}
                                        ) Textgestaltung (
                                        {gradesSystem.find(
                                          (g) =>
                                            g.points ===
                                            parseInt(
                                              categoryAverages.find(
                                                (c) =>
                                                  c.category ===
                                                  "Textgestaltung"
                                              )?.averagePoints || 0
                                            )
                                        )?.grade || "0"}
                                        )
                                      </Text>
                                    )}
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={1}>
                                    <Input
                                      style={{ width: "50px" }}
                                      value={
                                        gradesSystem.find(
                                          (g) =>
                                            g.points === parseInt(averagePoints)
                                        )?.grade
                                      }
                                      disabled
                                    ></Input>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              </>
                            );
                          }}
                        />
                      </>
                    ) : (
                      "keine Bewertungskriterien gesetzt"
                    )}
                    {/* <br />
            {examId !== "CQQ4YNsox6L7JMjmppUK" && (
              <Space>
                <Button
                  shape="round"
                  onClick={() => addCriterionToDB("linguistic")}
                >
                  Bewertungskriterium hinzufügen
                </Button>

                {criteria && MAX_CRITERIA - criteria.length <= 10 && (
                  <React.Fragment>
                    <br />
                    <small>
                      verbleibende Bewertungskriterien:{" "}
                      {MAX_CRITERIA - criteria.length}
                    </small>
                  </React.Fragment>
                )}
              </Space>
            )} */}
                  </div>
                )}
              {renderLinguisticCriteriaTable()}

              {examData.formData?.wassoll &&
                examData.formData.wassoll.join().includes("Inhalt") &&
                examData.formData.wassoll.join().includes("Sprache") && (
                  <>
                    <Title level={5}>{`Gesamtnote Aufgabe`}</Title>
                    <br />
                    <Space direction="horizontal">
                      <Input
                        value={
                          gradesSystem.find(
                            (grade) => grade.points === parseInt(languageGrade)
                          )?.grade || "N/A"
                        }
                        style={{ width: "50px" }}
                        disabled
                      />
                      {" x "}
                      <InputNumber
                        value={
                          examData?.formData.schreibensie
                            ? parseInt(examData?.formData.schreibensie)
                            : 50
                        }
                        style={{ width: "50px" }}
                        disabled
                      />
                      {"% + "}
                      <Input
                        value={
                          gradesSystem.find(
                            (grade) => grade.points === parseInt(contentGrade)
                          )?.grade || "N/A"
                        }
                        style={{ width: "50px" }}
                        disabled
                      />
                      {" x "}
                      <InputNumber
                        value={
                          examData?.formData.schreibensie
                            ? 100 - parseInt(examData?.formData.schreibensie)
                            : 50
                        }
                        style={{ width: "50px" }}
                        disabled
                      />
                      {"%  = "}
                      <Input
                        value={
                          gradesSystem.find(
                            (grade) =>
                              grade.points ===
                              parseInt(
                                (
                                  (languageGrade *
                                    (examData?.formData.schreibensie
                                      ? parseInt(
                                          examData?.formData.schreibensie
                                        )
                                      : 50) +
                                    contentGrade *
                                      (examData?.formData.schreibensie
                                        ? 100 -
                                          parseInt(
                                            examData?.formData.schreibensie
                                          )
                                        : 50)) /
                                  100
                                ).toFixed(0)
                              )
                          )?.grade || "N/A"
                        }
                        style={{ width: "50px" }}
                        disabled
                      />
                    </Space>
                  </>
                )}
              {submissionData?.tasks[
                parseInt(taskNum.replace("task", ""), 10) - 1
              ]?.lob && (
                <>
                  <div>
                    <Title level={5}>Hinweise zur Verbesserung</Title>
                    <Input.TextArea
                      value={hinweise}
                      onChange={(e) => setHinweise(e.target.value)}
                      onBlur={(e) => onChangeHinweiseInDB(e.target.value)}
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                  </div>
                  <br />
                  <div>
                    <Title level={5}>Positives Feedback</Title>
                    <Input.TextArea
                      value={lob}
                      onChange={(e) => setLob(e.target.value)}
                      onBlur={(e) => onChangeLobInDB(e.target.value)}
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                  </div>
                </>
              )}
            </>
          ),
        }
      : null,
    examData.formData?.wassoll &&
    examData.formData.wassoll.join().includes("Inhalt")
      ? {
          key: "4",
          label: "Inhalt",
          children: (
            <>
              {" "}
              {examData.formData?.wassoll &&
                examData.formData.wassoll.join().includes("Inhalt") && (
                  <div ref={ref7}>
                    {!submissionData.gradesSuggested &&
                      examData.formData?.wassoll &&
                      (examData.formData?.wassoll.join().includes("Sprache") ||
                        examData.formData?.wassoll
                          .join()
                          .includes("Inhalt")) && (
                        <>
                          <Popconfirm
                            title="Notenvorschläge"
                            description="Bitte stellen Sie sicher, dass Sie die sprachliche Korrektur abgeschlossen und somit auch die Transkription überprüft haben, bevor Sie fortfahren. Anpassungen, die für die Benotung berücksichtigt werden sollen, sind danach nicht mehr möglich."
                            onConfirm={() => {
                              message.info({
                                content:
                                  "Noten werden ermittelt. Dies kann mehrere Minuten in Anspruch nehmen.",
                                duration: 10,
                                onClick: () => message.destroy(),
                              });
                              suggestGrades();
                            }}
                            okText="Fortfahren"
                            cancelText="Abbrechen"
                            overlayStyle={{ maxWidth: "300px" }}
                          >
                            <Button
                              ref={ref6}
                              type="primary"
                              shape="round"
                              loading={
                                loadingSuggestedGrades ||
                                submissionData?.gradesLoading
                              }
                            >
                              Noten vorschlagen
                            </Button>
                          </Popconfirm>
                        </>
                      )}
                    {/* {submissionData.gradesSuggested && (
                      <Popover
                        content="Aktuell erhalten wir inkonsistente Antworten von Microsoft Azure. Wenn die Korrekturzeichen oder Begründungstexte nicht korrekt platziert sind oder nicht nachvollziehbar erscheinen, klicken Sie bitte auf diesen Button, um uns zu informieren. Wir analysieren und beheben den Fehler schnellstmöglich. Sobald ein neuer Notenvorschlag für diesen Aufsatz erstellt wurde, erhalten Sie eine Benachrichtigung."
                        title="Technischen Fehler melden"
                        overlayStyle={{ maxWidth: "300px" }}
                      >
                        <Popconfirm
                          title="Technischen Fehler melden"
                          description={
                            <div>
                              <p>
                                Bitte beschreiben Sie kurz das Problem:{" "}
                                <span style={{ color: "red" }}>*</span>
                              </p>
                              <TextArea
                                value={issueDescription}
                                onChange={(e) =>
                                  setIssueDescription(e.target.value)
                                }
                                // placeholder="z.B. Korrekturzeichen falsch platziert, Begründungstexte unpassend..."
                                autoSize={{ minRows: 2, maxRows: 4 }}
                                status={!issueDescription.trim() ? "error" : ""}
                                required
                              />
                              {!issueDescription.trim() && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "4px",
                                  }}
                                >
                                  <small>
                                    {" "}
                                    Bitte geben Sie eine Beschreibung ein
                                  </small>
                                </div>
                              )}
                              <p>
                                <small>
                                  Wir analysieren und beheben den Fehler
                                  schnellstmöglich. Sobald ein neuer
                                  Notenvorschlag für diesen Aufsatz erstellt
                                  wurde, erhalten Sie eine Benachrichtigung.
                                </small>
                              </p>
                            </div>
                          }
                          onConfirm={() => {
                            if (!issueDescription.trim()) {
                              message.error(
                                "Bitte geben Sie eine kurze Beschreibung des Problems ein."
                              );
                              return false; // Prevents the Popconfirm from closing
                            }
                            reportTechnicalIssue(issueDescription);
                            setIssueDescription(""); // Reset after submission
                          }}
                          okText="Melden"
                          cancelText="Abbrechen"
                          overlayStyle={{ maxWidth: "300px" }}
                        >
                          <Button
                            type="primary"
                            shape="round"
                            style={{
                              backgroundColor: "orange",
                              borderColor: "orange",
                            }}
                          >
                            Technischen Fehler melden
                          </Button>
                        </Popconfirm>
                      </Popover>
                    )} */}
                    <Title level={5}>
                      {/* {contentCriteriaName
                    ? contentCriteriaName
                    : "Inhaltliche Bewertungskriterien"} */}
                      Inhaltliche Bewertungskriterien
                    </Title>

                    {criteria ? (
                      <Table
                        dataSource={criteria
                          .filter((criterion) => criterion.type === "content")
                          .sort((a, b) => a.order - b.order)
                          .map((criterion) => {
                            return {
                              elemDescription: criterion,
                              elemPoints: criterion,
                              elemMax: criterion,
                              elemActions: criterion,
                            };
                          })}
                        columns={modifiedColumns}
                        locale={{
                          emptyText: "keine Bewertungskriterien gesetzt",
                        }}
                        showHeader={true}
                        pagination={false}
                        size="small"
                        summary={() => {
                          const linguisticCriteria = criteria.filter(
                            (criterion) => criterion.type === "linguistic"
                          );

                          let averagePoints;
                          let categoryAverages;

                          if (!clusterCategories) {
                            let totalPoints = 0;
                            linguisticCriteria.forEach((criterion) => {
                              const criterionId = criterion.id;
                              const points =
                                ratings.find((r) => r.id === criterionId)
                                  ?.points || 0;
                              totalPoints += points;
                            });
                            averagePoints = (
                              totalPoints / linguisticCriteria.length
                            ).toFixed(0);

                            setLanguageGrade(averagePoints);
                          } else {
                            // SPRACHLICHE BEWERTUNGSKRITERIEN - alle kriterien gleich gewichtet

                            // Gruppieren der Kriterien nach Unterkategorien
                            const categories = linguisticCriteria.reduce(
                              (acc, criterion) => {
                                const category =
                                  criterion.description.split(": ")[0]; // Extrahieren der Unterkategorie
                                if (!acc[category]) {
                                  acc[category] = [];
                                }
                                acc[category].push(criterion);
                                return acc;
                              },
                              {}
                            );

                            // Berechnen der durchschnittlichen Punkte für jede Unterkategorie
                            categoryAverages = Object.keys(categories).map(
                              (category) => {
                                const criteria = categories[category];
                                let totalPoints = 0;
                                criteria.forEach((criterion) => {
                                  const criterionId = criterion.id;
                                  const rating = ratings.find(
                                    (r) => r.id === criterionId
                                  );
                                  const points =
                                    rating && !isNaN(rating.points)
                                      ? rating.points
                                      : 0;
                                  totalPoints += points;
                                });
                                const averagePoints = (
                                  totalPoints / criteria.length
                                ).toFixed(0);
                                return {
                                  category,
                                  averagePoints,
                                };
                              }
                            );

                            averagePoints =
                              categoryAverages.reduce(
                                (acc, c) => acc + parseInt(c.averagePoints),
                                0
                              ) / categoryAverages.length;
                            averagePoints = averagePoints.toFixed(0);

                            setLanguageGrade(averagePoints);
                          }

                          const contentCriteria = criteria.filter(
                            (criterion) => criterion.type === "content"
                          );

                          let averagePointsContent;
                          let totalPoints = 0;
                          contentCriteria.forEach((criterion) => {
                            const criterionId = criterion.id;
                            const points =
                              ratings.find((r) => r.id === criterionId)
                                ?.points || 0;
                            totalPoints += points;
                          });
                          averagePointsContent = (
                            totalPoints / contentCriteria.length
                          ).toFixed(0);

                          setContentGrade(averagePointsContent);

                          return (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>
                                  <Text>Note Inhalt: ⌀ Einzelnoten</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                  <Input
                                    style={{ width: "50px" }}
                                    value={
                                      gradesSystem.find(
                                        (grade) =>
                                          grade.points ===
                                          parseInt(averagePointsContent)
                                      )?.grade || "N/A"
                                    }
                                    disabled
                                  ></Input>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          );
                        }}
                      />
                    ) : (
                      "keine Bewertungskriterien gesetzt"
                    )}

                    {examData.formData?.wassoll &&
                      examData.formData.wassoll.join().includes("Inhalt") &&
                      contentAnnotationsUmgangMitDemThema.length > 0 && (
                        <>
                          <br />
                          <Title level={5}>
                            Allgemeine Aufgabenerfüllung gemäß Operator
                          </Title>
                          <Text>
                            {ratings && ratings["8nz409OMDAmimz6n9H3e"]}
                          </Text>
                          <TextArea
                            value={
                              localExplanations["8nz409OMDAmimz6n9H3e"] || ""
                            }
                            onChange={(e) => {
                              setLocalExplanations((prev) => ({
                                ...prev,
                                ["8nz409OMDAmimz6n9H3e"]: e.target.value,
                              }));
                            }}
                            onBlur={(e) => {
                              onChangeRatingData("8nz409OMDAmimz6n9H3e", {
                                explanation: e.target.value,
                              });
                            }}
                            autoSize={{ minRows: 2, maxRows: 6 }}
                          />
                        </>
                      )}

                    {examData.formData?.wassoll &&
                      examData.formData.wassoll.join().includes("Inhalt") &&
                      contentAnnotationsUmgangMitDemThema.length > 0 && (
                        <>
                          <br />
                          <Title level={5}>
                            Inhaltliche Erschließung (Umgang mit dem Thema)
                          </Title>
                          <Table
                            dataSource={contentAnnotationsUmgangMitDemThema
                              .sort((a, b) => a.startLineId - b.startLineId)
                              .map((annotation, index) => ({
                                key: index,
                                name: "I" + (index + 1),
                                // line: `${annotation.startLineId}-${annotation.endLineId}`,
                                type: annotation.type,
                                reason: annotation.correct,
                              }))}
                            columns={columns_table_content}
                            pagination={false}
                            size="small"
                            bordered
                          />
                        </>
                      )}

                    {examData.formData?.wassoll &&
                      examData.formData.wassoll.join().includes("Inhalt") &&
                      contentAnnotationsInhaltlicherAufbau.length > 0 && (
                        <>
                          <br />
                          <Title level={5}>
                            Inhaltlicher Aufbau (Struktur, Stringenz, Kohärenz)
                          </Title>
                          <Table
                            dataSource={contentAnnotationsInhaltlicherAufbau
                              .sort((a, b) => a.startLineId - b.startLineId)
                              .map((annotation, index) => ({
                                key: index,
                                name: "A" + (index + 1),
                                // line: `${annotation.startLineId}-${annotation.endLineId}`,
                                type: annotation.type,
                                reason: annotation.correct,
                              }))}
                            columns={columns_table_content}
                            pagination={false}
                            size="small"
                            bordered
                          />
                        </>
                      )}
                  </div>
                )}
              {examData.formData?.wassoll &&
                examData.formData.wassoll.join().includes("Inhalt") &&
                examData.formData.wassoll.join().includes("Sprache") && (
                  <>
                    <Title level={5}>{`Gesamtnote Aufgabe`}</Title>
                    <br />
                    <Space direction="horizontal">
                      <Input
                        value={
                          gradesSystem.find(
                            (grade) => grade.points === parseInt(languageGrade)
                          )?.grade || "N/A"
                        }
                        style={{ width: "50px" }}
                        disabled
                      />
                      {" x "}
                      <InputNumber
                        value={
                          examData?.formData.schreibensie
                            ? parseInt(examData?.formData.schreibensie)
                            : 50
                        }
                        style={{ width: "50px" }}
                        disabled
                      />
                      {"% + "}
                      <Input
                        value={
                          gradesSystem.find(
                            (grade) => grade.points === parseInt(contentGrade)
                          )?.grade || "N/A"
                        }
                        style={{ width: "50px" }}
                        disabled
                      />
                      {" x "}
                      <InputNumber
                        value={
                          examData?.formData.schreibensie
                            ? 100 - parseInt(examData?.formData.schreibensie)
                            : 50
                        }
                        style={{ width: "50px" }}
                        disabled
                      />
                      {"%  = "}
                      <Input
                        value={
                          gradesSystem.find(
                            (grade) =>
                              grade.points ===
                              parseInt(
                                (
                                  (languageGrade *
                                    (examData?.formData.schreibensie
                                      ? parseInt(
                                          examData?.formData.schreibensie
                                        )
                                      : 50) +
                                    contentGrade *
                                      (examData?.formData.schreibensie
                                        ? 100 -
                                          parseInt(
                                            examData?.formData.schreibensie
                                          )
                                        : 50)) /
                                  100
                                ).toFixed(0)
                              )
                          )?.grade || "N/A"
                        }
                        style={{ width: "50px" }}
                        disabled
                      />
                    </Space>
                  </>
                )}
              {submissionData?.tasks[
                parseInt(taskNum.replace("task", ""), 10) - 1
              ]?.lob &&
                !submissionData?.tasks[
                  parseInt(taskNum.replace("task", ""), 10) - 1
                ]?.lobContent && (
                  <>
                    <div>
                      <Title level={5}>Hinweise zur Verbesserung</Title>
                      <Input.TextArea
                        value={hinweise}
                        onChange={(e) => setHinweise(e.target.value)}
                        onBlur={(e) => onChangeHinweiseInDB(e.target.value)}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                      />
                    </div>
                    <br />
                    <div>
                      <Title level={5}>Positives Feedback</Title>
                      <Input.TextArea
                        value={lob}
                        onChange={(e) => setLob(e.target.value)}
                        onBlur={(e) => onChangeLobInDB(e.target.value)}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                      />
                    </div>
                  </>
                )}
              {submissionData?.tasks[
                parseInt(taskNum.replace("task", ""), 10) - 1
              ]?.lobContent && (
                <>
                  <div>
                    <Title level={5}>Hinweise zur Verbesserung</Title>
                    <Input.TextArea
                      value={hinweiseContent}
                      onChange={(e) => setHinweiseContent(e.target.value)}
                      onBlur={(e) =>
                        onChangeHinweiseContentInDB(e.target.value)
                      }
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                  </div>
                  <br />
                  <div>
                    <Title level={5}>Positives Feedback</Title>
                    <Input.TextArea
                      value={lobContent}
                      onChange={(e) => setLobContent(e.target.value)}
                      onBlur={(e) => onChangeLobContentInDB(e.target.value)}
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                  </div>
                </>
              )}
            </>
          ),
        }
      : null,
    examData.formData && examData.formData.wassoll
      ? {
          key: "5",
          label: "EH",
          children: (
            <Space direction="vertical">
              <Table
                showHeader={false}
                dataSource={Object.keys(examData.formData)
                  .filter(
                    (key) =>
                      examData.formData[key] !== null &&
                      examData.formData[key] !== undefined &&
                      examData.formData[key].length > 0 &&
                      !["userid", "examid", "submission_id"].includes(key)
                  )
                  .map((key) => {
                    const field = fieldKeys.find(
                      (field) => field?.name.toLowerCase() === key.toLowerCase()
                    );
                    return {
                      key,
                      fieldText: field ? field.text : key,
                      value: stripHTML(examData.formData[key]),
                      order: fieldKeys.indexOf(field),
                    };
                  })
                  .sort((a, b) => a.order - b.order)}
                columns={[
                  {
                    title: "Feld",
                    dataIndex: "fieldText",
                    key: "fieldText",
                    align: "left",
                    className: "align-top-left",
                  },
                  {
                    title: "Wert",
                    dataIndex: "value",
                    key: "value",
                    align: "left",
                    className: "align-top-left",
                  },
                ]}
                pagination={false}
                size="small"
              />
              <Button disabled onClick={() => navigate(`/edit/${examId}`)}>
                Erwartungshorizont bearbeiten
              </Button>{" "}
            </Space>
          ),
        }
      : null,
  ];

  return (
    <React.Fragment>
      {taskType && taskType.includes("Anderer Aufgabentyp") && (
        <React.Fragment>
          <Title level={5}>Bewertungskriterien</Title>
          {criteria ? (
            <Table
              dataSource={criteria
                .filter((criterion) => criterion.type === "other")
                .sort((a, b) => a.description.localeCompare(b.description))
                .map((criterion) => {
                  return {
                    elemDescription: criterion,
                    elemPoints: criterion,
                    elemMax: criterion,
                    elemActions: criterion,
                  };
                })}
              columns={modifiedColumns}
              locale={{ emptyText: "keine Bewertungskriterien gesetzt" }}
              showHeader={true}
              pagination={false}
              size="small"
              // summary={() => {
              //   const { totalPoints, totalMax } = calculateTotals(
              //     criteria.filter((criterion) => criterion.type === "other"),
              //     submissionData
              //   );
              //   return (
              //     <>
              //       <Table.Summary.Row>
              //         <Table.Summary.Cell index={0}>
              //           <Text>∑</Text>
              //         </Table.Summary.Cell>
              //         <Table.Summary.Cell index={1}>
              //           <InputNumber
              //             value={totalPoints}
              //             style={{ width: "50px" }} // Setzen Sie die Breite hier
              //             disabled
              //           />{" "}
              //         </Table.Summary.Cell>
              //         <Table.Summary.Cell index={2}>
              //           <InputNumber
              //             value={totalMax}
              //             style={{ width: "50px" }} // Setzen Sie die Breite hier
              //             disabled
              //           />{" "}
              //         </Table.Summary.Cell>
              //       </Table.Summary.Row>
              //     </>
              //   );
              // }}
            />
          ) : (
            "keine Bewertungskriterien gesetzt"
          )}
          <br />
          {/* {examId !== "CQQ4YNsox6L7JMjmppUK" && (
            <Space>
              <Button shape="round" onClick={() => addCriterionToDB("other")}>
                Bewertungskriterium hinzufügen
              </Button>

              {criteria && MAX_CRITERIA - criteria.length <= 10 && (
                <React.Fragment>
                  <br />
                  <small>
                    verbleibende Bewertungskriterien:{" "}
                    {MAX_CRITERIA - criteria.length}
                  </small>
                </React.Fragment>
              )}
            </Space>
          )} */}
        </React.Fragment>
      )}
      {taskType && taskType.includes("Freitext") && (
        <Tabs defaultActiveKey="1" type="card" items={tabItems} />
      )}
    </React.Fragment>
  );
};

export default PointComponent;
