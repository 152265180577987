import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Table,
  ColorPicker,
  Typography,
  Popover,
  Space,
} from "antd";

import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { doc, updateDoc } from "firebase/firestore";
import db from "../utils/db"; // Ensure you have the correct path to your db utility
import { useAuth } from "../App";
const { Title, Text } = Typography;

const defaultCorrectionMarks = [
  {
    annotationCategory: "linguistic",
    category: "Handschrift",
    type: "Handschrift (kein Fehler)",
    abbreviation: "H",
    color: "#8699a1",
    customAbbreviation: "H",
    customColor: "#8699a1",
  },
  {
    annotationCategory: "linguistic",
    category: "Positiv",
    type: "Sprache positiv",
    abbreviation: "Sp+",
    color: "#059F09",
    customAbbreviation: "Sp+",
    customColor: "#059F09",
  },
  {
    annotationCategory: "linguistic",
    category: "Rechtschreibung",
    type: "Rechtschreibfehler",
    abbreviation: "R",
    color: "#ff4d4f",
    customAbbreviation: "R",
    customColor: "#ff4d4f",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "Grammatikfehler",
    abbreviation: "G",
    color: "#594DFF",
    customAbbreviation: "G",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "Präpositionsfehler",
    abbreviation: "G(P)",
    color: "#594DFF",
    customAbbreviation: "G(P)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "falsche Flexion",
    abbreviation: "G(Flx)",
    color: "#594DFF",
    customAbbreviation: "G(Flx)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "falsches Tempus",
    abbreviation: "G(T)",
    color: "#594DFF",
    customAbbreviation: "G(T)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "falscher Bezug",
    abbreviation: "G(B)",
    color: "#594DFF",
    customAbbreviation: "G(B)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "falscher Modus",
    abbreviation: "G(M)",
    color: "#594DFF",
    customAbbreviation: "G(M)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "Ergänzung von grammatisch Fehlendem",
    abbreviation: "G(√)",
    color: "#594DFF",
    customAbbreviation: "G(√)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "Streichung von grammatisch Überflüssigem",
    abbreviation: "G(-)",
    color: "#594DFF",
    customAbbreviation: "G(-)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Zeichensetzung",
    type: "Veränderung eines Satzzeichens",
    abbreviation: "Z",
    color: "#F406BC",
    customAbbreviation: "Z",
    customColor: "#F406BC",
  },
  {
    annotationCategory: "linguistic",
    category: "Zeichensetzung",
    type: "Ergänzung eines Satzzeichens",
    abbreviation: "Z(√)",
    color: "#F406BC",
    customAbbreviation: "Z(√)",
    customColor: "#F406BC",
  },
  {
    annotationCategory: "linguistic",
    category: "Zeichensetzung",
    type: "Streichung eines Satzzeichens",
    abbreviation: "Z(-)",
    color: "#F406BC",
    customAbbreviation: "Z(-)",
    customColor: "#F406BC",
  },
  {
    annotationCategory: "linguistic",
    category: "Satzbau",
    type: "falscher Satzbau",
    abbreviation: "Sb",
    color: "#C9CF35",
    customAbbreviation: "Sb",
    customColor: "#C9CF35",
  },
  {
    annotationCategory: "linguistic",
    category: "Satzbau",
    type: "Ergänzung von syntaktisch Fehlendem",
    abbreviation: "Sb(√)",
    color: "#C9CF35",
    customAbbreviation: "Sb(√)",
    customColor: "#C9CF35",
  },
  {
    annotationCategory: "linguistic",
    category: "Satzbau",
    type: "Streichung von syntaktisch Überflüssigem",
    abbreviation: "Sb(-)",
    color: "#C9CF35",
    customAbbreviation: "Sb(-)",
    customColor: "#C9CF35",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "falsches Wort, unpassendes Wort, falsche Wortwahl, lexikalischer Fehler",
    abbreviation: "A",
    color: "#F98F31",
    customAbbreviation: "A",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "Ergänzung von semantisch Fehlendem",
    abbreviation: "A(√)",
    color: "#F98F31",
    customAbbreviation: "A(√)",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "Streichung von semantisch Überflüssigem",
    abbreviation: "A(-)",
    color: "#F98F31",
    customAbbreviation: "A(-)",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "umgangssprachlicher, unangemessener Ausdruck",
    abbreviation: "A(ugs.)",
    color: "#F98F31",
    customAbbreviation: "A(ugs.)",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "Ausdruckswiederholung, mangelnde Variabilität",
    abbreviation: "A(Wdh.)",
    color: "#F98F31",
    customAbbreviation: "A(Wdh.)",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "falsches Idiom",
    abbreviation: "A(Id)",
    color: "#F98F31",
    customAbbreviation: "A(Id)",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "nicht textsortengerechter Stil, unangemessener Stil",
    abbreviation: "A(St)",
    color: "#F98F31",
    customAbbreviation: "A(St)",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltlich richtig",
    color: "#059F09",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#059F09",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltlich falsch",
    color: "#ff4d4f",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#ff4d4f",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltlich ungenau",
    color: "#594DFF",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltlich unlogisch",
    color: "#F406BC",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#F406BC",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltlich unvollständig",
    color: "#C9CF35",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#C9CF35",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltliche Wiederholung",
    color: "#F98F31",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltlich irrelevant",
    color: "#8699a1",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#8699a1",
  },
];

const CorrectionMarkDefinition = ({
  visible,
  onClose,
  examData,
  // user,
  examIdProp,
  annotations,
  showFrequencies,
}) => {
  const [correctionMarks, setCorrectionMarks] = useState(null);
  const [initialCorrectionMarks, setInitialCorrectionMarks] = useState(null);

  // const [editingIndex, setEditingIndex] = useState(null);

  const [editingLanguageIndex, setEditingLanguageIndex] = useState(null);
  const [editingContentIndex, setEditingContentIndex] = useState(null);

  const [inputError, setInputError] = useState(false);
  const [tempEditValue, setTempEditValue] = useState(null);

  // const handleEdit = (index) => {
  //   setEditingIndex(index);
  //   setTempEditValue({
  //     customColor: correctionMarks[index].customColor,
  //     customAbbreviation: correctionMarks[index].customAbbreviation,
  //   });
  // };

  // const handleSave = (index) => {
  //   setEditingIndex(null);
  //   setInputError(false);

  //   // setInitialCorrectionMarks(JSON.parse(JSON.stringify(correctionMarks)));

  //   if (
  //     JSON.stringify(correctionMarks) !== JSON.stringify(initialCorrectionMarks)
  //   ) {
  //     saveCorrectionMarks();
  //   }
  // };

  const handleEdit = (index, category) => {
    if (category === "linguistic") {
      setEditingLanguageIndex(index);
    } else {
      setEditingContentIndex(index);
    }
    setTempEditValue({
      customColor: correctionMarks[index].customColor,
      customAbbreviation: correctionMarks[index].customAbbreviation,
    });
  };

  const handleSave = (index, category) => {
    if (category === "linguistic") {
      setEditingLanguageIndex(null);
    } else {
      setEditingContentIndex(null);
    }
    setInputError(false);

    if (
      JSON.stringify(correctionMarks) !== JSON.stringify(initialCorrectionMarks)
    ) {
      saveCorrectionMarks();
    }
  };

  const handlePopoverVisibleChange = (visible, category) => {
    if (!visible) {
      handleSave(
        category === "linguistic" ? editingLanguageIndex : editingContentIndex,
        category
      );
    }
  };

  // const handleCancel = () => {
  //   setCorrectionMarks((prevMarks) => {
  //     const newMarks = [...prevMarks];
  //     newMarks[editingIndex] = { ...newMarks[editingIndex], ...tempEditValue };
  //     return newMarks;
  //   });
  //   setEditingIndex(null);
  //   setInputError(false);
  //   setTempEditValue(null);
  // };

  const handleCancel = (category) => {
    if (category === "linguistic") {
      setCorrectionMarks((prevMarks) => {
        const newMarks = [...prevMarks];
        newMarks[editingLanguageIndex] = {
          ...newMarks[editingLanguageIndex],
          ...tempEditValue,
        };
        return newMarks;
      });
      setEditingLanguageIndex(null);
    } else {
      setCorrectionMarks((prevMarks) => {
        const newMarks = [...prevMarks];
        newMarks[editingContentIndex] = {
          ...newMarks[editingContentIndex],
          ...tempEditValue,
        };
        return newMarks;
      });
      setEditingContentIndex(null);
    }
    setInputError(false);
    setTempEditValue(null);
  };

  const handleColorChange = (color, index, category) => {
    const hexColor = color.toHexString();
    setCorrectionMarks((prevMarks) => {
      const newMarks = [...prevMarks];
      const categoryMarks = newMarks.filter(
        (mark) => mark.annotationCategory === category
      );
      const globalIndex = newMarks.findIndex(
        (mark) => mark === categoryMarks[index]
      );

      if (globalIndex !== -1) {
        newMarks[globalIndex] = {
          ...newMarks[globalIndex],
          customColor: hexColor,
        };
      }
      return newMarks;
    });
    setTempEditValue((prev) => ({ ...prev, customColor: hexColor }));
  };

  const handleAbbreviationChange = (value, index, category) => {
    const trimmedValue = value.trim().slice(0, 7);
    setCorrectionMarks((prevMarks) => {
      const newMarks = [...prevMarks];
      newMarks[index] = {
        ...newMarks[index],
        customAbbreviation: trimmedValue,
      };
      return newMarks;
    });
    setTempEditValue((prev) => ({
      ...prev,
      customAbbreviation: trimmedValue,
    }));
    setInputError(trimmedValue.length === 0);
  };

  useEffect(() => {
    let marks;
    if (
      examData?.correctionMarks &&
      examData.correctionMarks.every((mark) => mark.customAbbreviation)
    ) {
      marks = examData.correctionMarks;
    } else {
      marks = [...defaultCorrectionMarks];
    }

    setCorrectionMarks(JSON.parse(JSON.stringify(marks)));
    setInitialCorrectionMarks(JSON.parse(JSON.stringify(marks)));
  }, [examData]);

  const { user } = useAuth();

  const saveCorrectionMarks = async () => {
    const docRef = doc(db, `users/${user.uid}/exams/${examIdProp}`);
    try {
      await updateDoc(docRef, { correctionMarks });
      setInitialCorrectionMarks(JSON.parse(JSON.stringify(correctionMarks)));
      // onClose();
    } catch (error) {
      console.error("Failed to save correction marks:", error);
    }
  };

  const columnsLanguage = [
    {
      title: "Korrekturzeichen",
      dataIndex: "customAbbreviation",
      width: 100,
      render: (text, record, index) => (
        <Popover
          content={
            editingLanguageIndex === index ? (
              <Space direction="vertical" align="center">
                <Space direction="horizontal">
                  <ColorPicker
                    value={record.customColor}
                    onChange={(color) =>
                      handleColorChange(color, index, record.annotationCategory)
                    }
                  />
                  <Input
                    value={record.customAbbreviation}
                    onChange={(e) =>
                      handleAbbreviationChange(
                        e.target.value,
                        index,
                        "linguistic"
                      )
                    }
                    maxLength={7}
                    style={{ marginTop: 8, width: 90 }}
                    status={inputError ? "error" : ""}
                  />
                  <Button
                    onClick={() => handleSave(index, "linguistic")}
                    style={{ marginTop: 8 }}
                    icon={<SaveOutlined />}
                    disabled={
                      inputError ||
                      record.customAbbreviation.trim().length === 0
                    }
                  />
                  <Button
                    onClick={() => handleCancel("linguistic")}
                    style={{ marginTop: 8 }}
                    icon={<CloseCircleOutlined />}
                  />
                </Space>
                <Text
                  type="secondary"
                  style={{
                    fontSize: "0.8em",
                    textAlign: "center",
                    color: inputError ? "red" : "",
                  }}
                >
                  {inputError ? "Eingabe erforderlich" : "maximal 7 Zeichen"}
                </Text>
              </Space>
            ) : null
          }
          trigger="click"
          open={editingLanguageIndex === index}
          onVisibleChange={(visible) =>
            handlePopoverVisibleChange(visible, "linguistic")
          }
        >
          <Text
            style={{
              color: record.customColor,
              fontSize: "1.2em",
              fontFamily: "Times New Roman",
              cursor: "pointer",
            }}
            onClick={() => handleEdit(index, "linguistic")}
          >
            {text}
          </Text>
        </Popover>
      ),
    },

    {
      title: "Bedeutung",
      dataIndex: "type",
      render: (text, record, index) => <Text>{text}</Text>,
    },
    showFrequencies
      ? {
          title: "Anzahl",
          dataIndex: "count",
          width: 50,
          render: (text, record, index) => (
            <Text>
              {
                annotations.filter((annotation) => {
                  const types = annotation.type.split("|");
                  return types.some((type) => type === record.abbreviation);
                }).length
              }
            </Text>
          ),
        }
      : null,
  ];

  const columnsContent = [
    // {
    //   title: "Farbe",
    //   dataIndex: "color",
    //   width: 100,
    //   render: (text, record, index) => (
    //     <Text
    //       style={{
    //         color: record.color,
    //         fontSize: "1.2em",
    //         fontFamily: "Times New Roman",
    //       }}
    //     >
    //       {"I1 / A1"}
    //     </Text>
    //   ),
    // },
    {
      title: "Korrekturzeichen",
      dataIndex: "customAbbreviation",
      width: 100,
      render: (text, record, index) => (
        <Popover
          content={
            editingContentIndex === index ? (
              <Space direction="vertical" align="center">
                <Space direction="horizontal">
                  <ColorPicker
                    value={record.customColor}
                    onChange={(color) =>
                      handleColorChange(color, index, record.annotationCategory)
                    }
                  />
                  <Button
                    onClick={() => handleSave(index, "content")}
                    style={{ marginTop: 8 }}
                    icon={<SaveOutlined />}
                  />
                  <Button
                    onClick={() => handleCancel("content")}
                    style={{ marginTop: 8 }}
                    icon={<CloseCircleOutlined />}
                  />
                </Space>
              </Space>
            ) : null
          }
          trigger="click"
          open={editingContentIndex === index}
          onVisibleChange={(visible) =>
            handlePopoverVisibleChange(visible, "content")
          }
        >
          <Text
            style={{
              color: record.customColor,
              fontSize: "1.2em",
              fontFamily: "Times New Roman",
              cursor: "pointer",
            }}
            onClick={() => handleEdit(index, "content")}
          >
            {"I1"}
          </Text>
        </Popover>
      ),
    },

    {
      title: "Bedeutung",
      dataIndex: "type",
      render: (text, record, index) => <Text>{text}</Text>,
    },
    showFrequencies
      ? {
          title: "Anzahl",
          dataIndex: "count",
          width: 50,
          render: (text, record, index) => (
            <Text>
              {
                annotations.filter((annotation) =>
                  annotation.type.includes(record.type)
                ).length
              }
            </Text>
          ),
        }
      : null,
  ];

  return (
    <>
      <Title level={5}>Korrekturzeichen Sprache</Title>
      <Table
        showHeader={false}
        size="small"
        columns={columnsLanguage.filter(Boolean)}
        dataSource={correctionMarks?.filter(
          (mark) => mark.annotationCategory === "linguistic"
        )}
        rowKey={(record, index) => index}
        pagination={false}
      />
      <Title level={5}>Korrekturzeichen Inhalt</Title>
      <Table
        showHeader={false}
        size="small"
        columns={columnsContent.filter(Boolean)}
        dataSource={correctionMarks?.filter(
          (mark) => mark.annotationCategory === "content"
        )}
        rowKey={(record, index) => index}
        pagination={false}
      />
    </>
  );
};

export { CorrectionMarkDefinition, defaultCorrectionMarks };
