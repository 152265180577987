import React from "react";

import {
  FileSearchOutlined,
  EditOutlined,
  SplitCellsOutlined,
  FileAddOutlined,
  CheckCircleOutlined,
  CloudUploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { Steps } from "antd";

const StepsComponent = ({ step }) => {
  const stepsItems = [
    {
      title: "1. Erwartungshorizont anlegen",
      icon: <FileAddOutlined />,
    },
    {
      title: "2. Dokument hochladen & Aufsätze einteilen",
      icon: <CloudUploadOutlined />,
    },
    {
      title: "3. Korrekturvorschlag überprüfen",
      icon: step === 3 ? <LoadingOutlined /> : <EditOutlined />,
    },
  ];

  const updatedStepsItems = stepsItems.map((item, index) => ({
    ...item,
    status: index < step ? "finish" : index === step ? "process" : "wait",
  }));

  return <Steps direction="vertical" size="small" items={updatedStepsItems} />;
};

export default StepsComponent;
