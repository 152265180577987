import React, { useState, useEffect, forwardRef } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";

import {
  useDocumentData,
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";

import { FaMedal, FaInfoCircle } from "react-icons/fa";
import { FcPlus } from "react-icons/fc";

import db from "../utils/db";

import {
  collection,
  query,
  where,
  addDoc,
  doc,
  onSnapshot,
  getDoc,
  setDoc,
  getDocs,
} from "firebase/firestore"; // Add this import

import {
  Layout,
  Menu,
  Avatar,
  Dropdown,
  Button,
  Spin,
  notification,
  Space,
  Radio,
  Modal,
  message,
  Typography,
  Row,
  Col,
  Card,
  Tooltip,
  Alert,
} from "antd";

import { FileSearchOutlined, FileAddOutlined } from "@ant-design/icons";

import SubscriptionModal from "../components/SubscriptionModal";
import { useSubscription } from "../hooks/useSubscription";

import { useAuth } from "../App";

// import { useClerk, useUser } from "@clerk/clerk-react";

import functions from "../utils/functions";

import { httpsCallable } from "firebase/functions";

import ZengradeLogo from "../assets/zengrade-logo-nobackground.svg";

import "./LayoutPage.css";

const { Header } = Layout;
const { Title, Text } = Typography;

const LayoutPage = () => {
  const { user, signout } = useAuth();

  const {
    loadingStripe,
    isSubscriptionModalVisible,
    showSubscriptionModal,
    handleSubscriptionModalCancel,
    handleSubscribe,
  } = useSubscription(user);

  const handleLogoutClick = (e) => {
    e.preventDefault();
    signout();
  };

  // const [loadingStripe, setLoadingStripe] = useState(false);

  const [
    subscriptionCollection,
    // eslint-disable-next-line no-unused-vars
    subscriptionCollectionLoading,
    // eslint-disable-next-line no-unused-vars
    subscriptionCollectionError,
  ] = useCollection(
    query(collection(db, `customers/${user.uid}/subscriptions`)),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [userData, userLoading, userDataError] = useDocumentData(
    doc(db, `users`, user.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const [api, contextHolder] = notification.useNotification();

  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("payment_success") === "true") {
      api.success({
        message: "Zahlung erfolgreich",
        description:
          "Die zenpoints wurden Ihrem Konto erfolgreich gutgeschrieben und stehen nun zur Verfügung.",
        duration: 5,
        placement: "bottom",
      });
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    // if (urlParams.get("creation_success") === "true") {
    //   api.success({
    //     message: "Erwartungshorizont angelegt",
    //     description:
    //       "Der Erwartungshorizont wurde erfolgreich angelegt. Sie können nun einen Scan hochladen.",
    //     duration: 5,
    //     placement: "bottom",
    //   });
    //   window.history.replaceState({}, document.title, window.location.pathname);
    // }

    if (urlParams.get("creation_success") === "true") {
      const closeNotification = () => {
        api.destroy();
      };

      api.warning({
        message: "Erwartungshorizont erfolgreich angelegt",
        type: "info",
        style: { width: "100%", marginTop: "50px" },
        description: (
          <div
            style={{
              maxHeight: "70vh",
              overflowY: "auto",
              paddingRight: "10px",
            }}
          >
            <Alert
              message="Sie können nun das PDF mit den Scans hochladen. Um die bestmöglichen Ergebnisse zu erzielen, sollten Bedingungen geschaffen werden, die es der Handschrifterkennung leicht macht, gute Ergebnisse zu liefern. Daher geben wir Empfehlungen dazu, wie die Handschrifterkennung aktiv unterstützt werden kann. Die Empfehlungen müssen nicht zwingend eingehalten werden, wir garantieren bestmögliche Ergebnisse aber nur dann. Insgesamt ist die Bereitstellung sauberer Daten essenziell, weil nur so die Genauigkeit unserer Handschrifterkennung maximiert werden kann:"
              showIcon={false}
              style={{ marginBottom: "20px", width: "100%" }}
            />
            <div>
              <p>
                <b>Merkmale Scan:</b>
              </p>
              <ul>
                <li>Scan am Multifunktionsgerät, keine Fotos</li>
                <li>Auflösung 300 dpi</li>
                <li>Scan in Farbe</li>
                <li>Hochformat A4</li>
                <li>richtige Reihenfolge der Seiten</li>
                <li>Seiten nicht gedreht</li>
              </ul>
            </div>
            <br />
            <small>
              Wichtig: Wählen Sie am Scanner nicht die automatische Erkennung
              von Format, Farbe, etc.
            </small>
            <br />
            <br />
            <p>
              <b>Merkmale Dokument:</b>
            </p>
            <div>
              <ul>
                <li>handschriftlich auf Zettel (kein getippter Text/Stylus)</li>
                <li>blaue Schrift</li>
                <li>Bogen mit Lineatur 27, Schrift in jede 2. Zeile</li>
                <li>nur eine Aufgabe</li>
                <li>maximal 22 Seiten pro Aufsatz</li>
              </ul>
            </div>
            <p>
              <b>
                Sie haben diese Hinweise zusätzlich per E-Mail erhalten. Darüber
                hinaus empfehlen wir, den Schülerinnen und Schülern unser{" "}
                <a
                  href="https://youtu.be/y9XTEAN-okQ?si=ecuCFK1ONtXq1AYW"
                  target="_blank"
                  rel="noreferrer"
                >
                  Erklärvideo
                </a>{" "}
                zu zeigen.
              </b>
            </p>
            <div style={{ textAlign: "right", marginTop: "20px" }}>
              <Button type="primary" onClick={closeNotification}>
                Schließen
              </Button>
            </div>
          </div>
        ),
        duration: 0,
        placement: "top",
      });
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [api]);

  // useEffect(() => {
  //   if (userLoading) return; // Don't do anything while loading

  //   const showWelcomeNotification = () => {
  //     const closeNotification = () => {
  //       api.destroy();
  //     };

  //     api.info({
  //       message: "Willkommen bei zengrade!",
  //       description: (
  //         <div>
  //           <p>Dies ist Ihr erster Login. Viel Spaß mit zengrade!</p>
  //           <Button type="primary" onClick={closeNotification}>
  //             Schließen
  //           </Button>
  //         </div>
  //       ),
  //       duration: 0,
  //       placement: "top",
  //     });
  //   };

  //   const createOrUpdateUserDocument = async () => {
  //     const userRef = doc(db, `users`, user.uid);
  //     try {
  //       await setDoc(
  //         userRef,
  //         { shownFirstTimeNotification: true },
  //         { merge: true }
  //       );
  //       console.log("User document created/updated with first-time flag");
  //     } catch (error) {
  //       console.error("Error creating/updating user document:", error);
  //     }
  //   };

  //   if (userData === null || userData === undefined) {
  //     // User document doesn't exist yet or hasn't loaded, wait a bit and check again
  //     const timer = setTimeout(() => {
  //       if (userData === null || userData === undefined) {
  //         // If still null/undefined after delay, show notification and create document
  //         showWelcomeNotification();
  //         createOrUpdateUserDocument();
  //       }
  //     }, 2000); // Wait for 2 seconds

  //     return () => clearTimeout(timer); // Clean up the timer
  //   } else if (userData && !userData.shownFirstTimeNotification) {
  //     // User document exists but notification hasn't been shown
  //     showWelcomeNotification();
  //     createOrUpdateUserDocument();
  //   }
  // }, [userData, userLoading, api, user.uid]);

  // const purchaseSubscription = async (plan) => {
  //   message.info("Sie werden weitergeleitet...");

  //   // setLoadingStripe(true);
  //   try {
  //     const priceId =
  //       plan === "Silber"
  //         ? "price_1PlTbiIBcEHZy5P9GqesgWVy"
  //         : "price_1PlTbdIBcEHZy5P9rUiZxVA0";

  //     const docRef = await addDoc(
  //       collection(db, "customers", user.uid, "checkout_sessions"),
  //       {
  //         mode: "subscription",
  //         line_items: [
  //           {
  //             price: priceId,
  //             quantity: 1,
  //           },
  //         ],
  //         success_url: window.location.origin + "?payment_success=true",
  //         cancel_url: window.location.origin,
  //         client_reference_id: user.uid,
  //         automatic_tax: { enabled: true },
  //         consent_collection: {
  //           terms_of_service: "required",
  //         },
  //       }
  //     );

  //     onSnapshot(docRef, (snap) => {
  //       const { error, url } = snap.data();
  //       if (error) {
  //         console.error(`An error occurred: ${error.message}`);
  //         setLoadingStripe(false);
  //       }
  //       if (url) {
  //         window.location.assign(url);
  //       }
  //     });
  //   } catch (error) {
  //     console.error("Error creating checkout session:", error);
  //     message.error(
  //       "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
  //     );
  //   } finally {
  //     setLoadingStripe(false);
  //   }
  // };

  const buyAdditionalZenpoints = async () => {
    message.loading("Sie werden weitergeleitet...", 15);

    // setLoadingStripe(true);
    try {
      const priceId =
        activeSubscriptionName === "Gold"
          ? "price_1PlTbRIBcEHZy5P9Z5wAn3OM"
          : "price_1PlTbZIBcEHZy5P9lPu6rgf8";

      const docRef = await addDoc(
        collection(db, "customers", user.uid, "checkout_sessions"),
        {
          mode: "payment",
          line_items: [
            {
              price: priceId,
              quantity: 1,
              adjustable_quantity: {
                enabled: true,
                minimum: 1,
                maximum: 1000,
              },
            },
          ],
          success_url: window.location.origin + "?payment_success=true",
          cancel_url: window.location.origin,
          client_reference_id: user.uid,
          automatic_tax: { enabled: true },
          allow_promotion_codes: true,
          locale: "de",
          invoice_creation: {
            enabled: true,
          },
        }
      );

      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          console.error(`An error occurred: ${error.message}`);
          // setLoadingStripe(false);
        }
        if (url) {
          window.location.assign(url);
        }
      });
    } catch (error) {
      message.error(
        "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
      );
    } finally {
      // setLoadingStripe(false);
    }
  };

  const hasActiveSubscription =
    subscriptionCollection &&
    subscriptionCollection.docs.length > 0 &&
    subscriptionCollection.docs.some((doc) => doc.data().status === "active");

  const [activeSubscriptionName, setActiveSubscriptionName] = useState("");

  useEffect(() => {
    const getSubscriptionName = async () => {
      if (hasActiveSubscription && subscriptionCollection) {
        const activeSubscription = subscriptionCollection.docs.find(
          (doc) => doc.data().status === "active"
        );

        if (activeSubscription) {
          const subscriptionData = activeSubscription.data();

          // Get the price ID from the subscription data
          const priceId = subscriptionData.items[0]?.price?.id;

          if (!priceId) {
            setActiveSubscriptionName("Unbekannt");
            return;
          }

          // Define the price IDs for Silber and Gold subscriptions
          const silberPriceId = "price_1PlTbiIBcEHZy5P9GqesgWVy";
          const goldPriceId = "price_1PlTbdIBcEHZy5P9rUiZxVA0";

          switch (priceId) {
            case silberPriceId:
              setActiveSubscriptionName("Silber");
              break;
            case goldPriceId:
              setActiveSubscriptionName("Gold");
              break;
            default:
              console.log(`Unexpected price ID: ${priceId}`);
              setActiveSubscriptionName("Unbekannt");
          }
        } else {
          setActiveSubscriptionName("");
        }
      } else {
        setActiveSubscriptionName("");
      }
    };

    getSubscriptionName();
  }, [hasActiveSubscription, subscriptionCollection]);

  const [loadingPortal, setLoadingPortal] = useState(false);

  const goToCustomerPortal = async () => {
    message.loading("Sie werden weitergeleitet...", 15);

    setLoadingPortal(true);
    try {
      // Fetch Stripe customer ID from Firestore
      const customerDoc = await getDoc(doc(db, "customers", user.uid));
      const stripeCustomerId = customerDoc.data()?.stripeId;

      if (!stripeCustomerId) {
        throw new Error("Stripe customer ID not found");
      } else {
        console.log(`stripeCustomerId: ${stripeCustomerId}`);
      }

      const createCustomStripePortalSession = httpsCallable(
        functions,
        "createCustomStripePortalSession"
      );
      const { data } = await createCustomStripePortalSession({
        customerId: stripeCustomerId,
        returnUrl: window.location.origin,
      });
      window.location.assign(data.url);
    } catch (error) {
      console.error("Error:", error);
      message.error("Fehler beim Abrufen des Kundenportals.");
    } finally {
      setLoadingPortal(false);
    }
  };

  const totalCredits =
    (userData?.subscriptionCredits || 0) + (userData?.permanentCredits || 0);

  const userMenuItems = [
    {
      key: "info",
      label: (
        <center>
          <b>{user.email}</b>
          {hasActiveSubscription && <br />}
          {hasActiveSubscription && (
            <small onClick={goToCustomerPortal} style={{ cursor: "pointer" }}>
              <span style={{ textDecoration: "underline" }}>
                zengrade {activeSubscriptionName}{" "}
              </span>
              <FaMedal
                style={{
                  color:
                    activeSubscriptionName === "Silber" ? "silver" : "gold",
                }}
              />
            </small>
          )}
          <br />
          {hasActiveSubscription && (
            <small>
              <Tooltip title="zenpoints aus dem Abonnement (bis Monatsende einlösbar)">
                <FaInfoCircle />
              </Tooltip>{" "}
              zenpoints{" "}
              <FaMedal
                style={{
                  color:
                    activeSubscriptionName === "Silber" ? "silver" : "gold",
                }}
              />{" "}
              {userData?.subscriptionCredits || 0}
            </small>
          )}
          {hasActiveSubscription && <br />}
          {userData?.permanentCredits > 0 && (
            <small>
              <Tooltip title="zusätzlich erworbene zenpoints (zeitl. unbegrenzt einlösbar)">
                <FaInfoCircle />
              </Tooltip>{" "}
              zenpoints <FcPlus style={{ color: "green" }} />{" "}
              {userData?.permanentCredits}
            </small>
          )}
          {hasActiveSubscription && <br />}
        </center>
      ),
    },
    {
      key: "subscribe",
      label: !hasActiveSubscription && (
        <Button
          shape="round"
          type="primary"
          className="user-menu-button"
          onClick={showSubscriptionModal}
          size="small"
        >
          zenpoints aufladen
        </Button>
      ),
    },
    {
      key: "buy-zenpoints",
      label: hasActiveSubscription && (
        <Button
          shape="round"
          className="user-menu-button"
          onClick={buyAdditionalZenpoints}
          size="small"
        >
          zenpoints aufladen
        </Button>
      ),
    },
    {
      key: "logout",
      label: (
        <Button
          shape="round"
          className="user-menu-button"
          onClick={handleLogoutClick}
          size="small"
        >
          Logout
        </Button>
      ),
    },
  ];

  const menuItems = [
    {
      key: "1",
      icon: <FileAddOutlined />,
      label: <Link to="/create">Erwartungshorizont anlegen</Link>,
    },
    {
      key: "2",
      icon: <FileSearchOutlined />,
      label: <Link to="/view">Aufsätze korrigieren</Link>,
      className:
        location.pathname.includes("/view") || location.pathname === "/"
          ? "ant-menu-item-selected"
          : "",
    },
    {
      key: "3",
      label: (
        <div style={{ display: "inline-block" }}>
          <Dropdown
            menu={{ items: userMenuItems }}
            placement="bottomRight"
            trigger={["click"]}
          >
            <Avatar style={{ cursor: "pointer" }}>
              {user?.email[0]?.toUpperCase()}
            </Avatar>
          </Dropdown>
        </div>
      ),
    },
    {
      key: "4",
      disabled: true,
      className: "zenpoints-display",
      style: { cursor: "default" },
      label: <span>zenpoints: {totalCredits}</span>,
    },
  ];

  return (
    <Layout>
      {contextHolder}
      <Header style={{ backgroundColor: "#35393C" }}>
        <div className="company-logo">
          <img src={ZengradeLogo} alt="logo" className="logo-img" />
          zengrade
        </div>
        {user && (
          <React.Fragment>
            {user && (
              <Menu
                style={{ backgroundColor: "#35393C" }}
                theme="dark"
                mode="horizontal"
                overflowedIndicator={<></>}
                items={menuItems}
              />
            )}
            {/* <Menu
              style={{
                backgroundColor: "#35393C",
              }}
              theme="dark"
              mode="horizontal"
              overflowedIndicator={<></>}
            >
              <Menu.Item key="1" icon={<FileAddOutlined />}>
                <span>
                  <Link to="/create">Erwartungshorizont anlegen</Link>
                </span>
              </Menu.Item>
              <Menu.Item key="3" icon={<FileSearchOutlined />}>
                <span>
                  <Link to="/view">Aufsätze korrigieren</Link>
                </span>
              </Menu.Item>

              <Menu.Item key="5">
                <Dropdown overlay={<UserMenu />} placement="bottomRight">
                  <Avatar>{user.email[0]?.toUpperCase()}</Avatar>
                </Dropdown>
              </Menu.Item>
              <Menu.Item
                disabled
                style={{
                  cursor: "default",
                }}
                className="zenpoints-display"
                key="5"
              >
                <span>zenpoints: {totalCredits}</span>
              </Menu.Item>
            </Menu> */}
          </React.Fragment>
        )}
      </Header>
      <div className="content">
        <Spin spinning={loadingStripe || loadingPortal}>
          <Outlet />
        </Spin>
      </div>
      <SubscriptionModal
        isOpen={isSubscriptionModalVisible}
        onCancel={handleSubscriptionModalCancel}
        onSubscribe={handleSubscribe}
      />
    </Layout>
  );
};

export default LayoutPage;
